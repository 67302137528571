import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;
export const CardContainer = styled.div`
  border-radius: var(--fd-radii-border-radius-020);
  height: 320px;
  width: 343px;
  border: 1px solid var(--fd-colors-border-subtle);
`;
export const RunnerInfoContainer = styled.div`
  & > div {
    &:nth-child(-n + 2) {
      border-bottom: 1px solid var(--fd-colors-border-subtle);
    }
  }
`;
export const HeaderContainer = styled.div`
  border-bottom: 1px solid var(--fd-colors-border-subtle);
  height: 73px;
`;
