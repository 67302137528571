import React from "react";
import { Button } from "@tvg/design-system/web";
import { OddsButtonProps } from "./types";
import {
  OddsContainer,
  MessageContainer,
  TextContainer
} from "./styled-components";
import TagContainer from "../TagContainer";

const OddsButton = ({
  formattedOdds,
  qaLabel,
  isScratched,
  onClick
}: OddsButtonProps) => {
  if (isScratched) {
    return <TagContainer label="scratched" variant="neutral" isScratched />;
  }
  return (
    <Button variant="secondary" size="ml" qaLabel={qaLabel} onClick={onClick}>
      <TextContainer>
        <OddsContainer>{formattedOdds}</OddsContainer>
        <MessageContainer>TO WIN</MessageContainer>
      </TextContainer>
    </Button>
  );
};
export default OddsButton;
