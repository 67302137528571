import { get } from "lodash";
import type { PotentialReturnUtils, ProbableValueList } from "../generalTypes";
import { calculateProbableValue } from "../generalUtils";

export const exactaAndDailyDouble = ({
  betSelections,
  betCombosHash,
  minWagerAmount,
  betAmount
}: PotentialReturnUtils): ProbableValueList => {
  const probableValueList: ProbableValueList = [];
  const firstPlaceSelectionsLength = get(betSelections, "[0].length", 0);
  const secondPlaceSelectionsLength = get(betSelections, "[1].length", 0);

  if (firstPlaceSelectionsLength > 0 && secondPlaceSelectionsLength > 0) {
    try {
      // There are selections, now find combinations and add payouts to probableValueList
      get(betSelections, "[0]", []).forEach(
        (firstPlaceSelection: Array<string>) => {
          get(betSelections, "[1]", []).forEach(
            (secondPlaceSelection: string) => {
              const payout =
                betCombosHash[`${firstPlaceSelection}-${secondPlaceSelection}`];

              if (typeof payout === "string") {
                if (payout !== "0.0") {
                  probableValueList.push(
                    calculateProbableValue(+payout, minWagerAmount, betAmount)
                  );
                }
              } else {
                throw new Error("NaN");
              }
            }
          );
        }
      );
    } catch (e) {
      return [];
    }
  }

  return probableValueList;
};

export default exactaAndDailyDouble;
