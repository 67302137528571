import {
  ComponentMapping,
  HomepageStoryblokConfig,
  HomepageTemplate,
  SectionConfigType,
  HomepageStoryblokMobileConfig,
  ComponentProps
} from "../types";
import componentsMapping from "../componentsMapping";

export const renderComponent = (componentObj: ComponentProps) => {
  const componentToRender = (componentsMapping as ComponentMapping)[
    componentObj.componentName as keyof ComponentMapping
  ]?.component;

  return componentToRender?.(componentObj) ?? null;
};

export const renderComponents = (
  templates: HomepageStoryblokConfig | HomepageStoryblokMobileConfig,
  groupName: SectionConfigType,
  selectedTemplate: string
) => {
  const selectedTemplateComponents = templates?.[
    selectedTemplate
  ] as unknown as HomepageTemplate;

  const groupComponents =
    selectedTemplateComponents?.[groupName as keyof HomepageTemplate];

  return Array.isArray(groupComponents)
    ? groupComponents.map(renderComponent).filter(Boolean)
    : null;
};

export default renderComponents;
