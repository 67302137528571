import React from "react";
import {
  Container,
  CornerBorder,
  BottomBorder,
  SVG
} from "./styled-components";
import { LoadingProps } from "../types";

export const LoadingBlock = ({ isDarkMode }: LoadingProps) => {
  const classNameWithTheme = isDarkMode ? "darkPulse" : "lightPulse";
  const fillOpacity = isDarkMode ? "0.05" : "none";
  const borderPathFillColor = isDarkMode ? "#1C1D1D" : "#FFF";
  const svgPathFillColor = isDarkMode ? "#4D5153" : "#C6D3E1";
  const svgMask = isDarkMode
    ? "url(#path-1-inside-1_2777_9008)"
    : "url(#path-1-inside-1_2777_16599)";
  const pathSvgMask = isDarkMode
    ? "url(#path-9-inside-2_2777_9008)"
    : "url(#path-9-inside-2_2777_16599)";

  return (
    <Container
      data-qa-label={isDarkMode ? "darkMode-loading" : "lightMode-loading"}
    >
      <CornerBorder isDarkMode={isDarkMode}>
        <BottomBorder isDarkMode={isDarkMode}>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="340"
            height="71"
            viewBox="0 0 340 71"
            fill="none"
          >
            <path
              d="M0 3C0 1.34314 1.34315 0 3 0H337C338.657 0 340 1.34315 340 3V71H0V3Z"
              fill={borderPathFillColor}
            />
            <rect
              className={classNameWithTheme}
              x="16"
              y="19"
              width="173"
              height="14"
              rx="2"
              fill="white"
              fillOpacity={fillOpacity}
            />
            <rect
              className={classNameWithTheme}
              x="16"
              y="42"
              width="63"
              height="11"
              rx="1"
              fill="white"
              fillOpacity={fillOpacity}
            />
            <rect
              className={classNameWithTheme}
              x="226"
              y="39"
              width="98"
              height="17"
              rx="1"
              fill="white"
              fillOpacity={fillOpacity}
            />
          </SVG>
        </BottomBorder>
        <SVG
          xmlns="http://www.w3.org/2000/svg"
          width="340"
          height="245"
          viewBox="0 0 340 245"
          fill="none"
        >
          <mask id="path-1-inside-1_2777_9008" fill="white">
            <path d="M0 0H340V82H0V0Z" />
          </mask>
          <path d="M0 0H340V82H0V0Z" fill={borderPathFillColor} />
          <path
            d="M340 81H0V83H340V81Z"
            fill={svgPathFillColor}
            mask={svgMask}
          />
          <path
            className={classNameWithTheme}
            d="M0 8H28C30.2091 8 32 9.79086 32 12V69C32 71.2091 30.2091 73 28 73H0V8Z"
            fill={isDarkMode ? "white" : "#0A0A0A"}
            fillOpacity={fillOpacity}
          />
          <rect
            className={classNameWithTheme}
            x="46"
            y="10.5"
            width="156"
            height="14"
            rx="2"
            fill="white"
            fillOpacity={fillOpacity}
          />
          <rect
            className={classNameWithTheme}
            x="46"
            y="30.5"
            width="205"
            height="12"
            rx="2"
            fill="white"
            fillOpacity={fillOpacity}
          />
          <rect
            className={classNameWithTheme}
            x="46"
            y="45.5"
            width="205"
            height="12"
            rx="2"
            fill="white"
            fillOpacity={fillOpacity}
          />
          <rect
            className={classNameWithTheme}
            x="46"
            y="60.5"
            width="171"
            height="12"
            rx="2"
            fill="white"
            fillOpacity={fillOpacity}
          />
          <rect
            className={classNameWithTheme}
            x="268"
            y="18.5"
            width="60"
            height="44"
            rx="4"
            fill="white"
            fillOpacity={fillOpacity}
          />
          <mask id={pathSvgMask} fill="white">
            <path d="M0 82H340V164H0V82Z" />
          </mask>
          <path d="M0 82H340V164H0V82Z" fill={borderPathFillColor} />
          <path
            d="M340 163H0V165H340V163Z"
            fill={svgPathFillColor}
            mask={pathSvgMask}
          />
          <path
            className={classNameWithTheme}
            d="M0 90H28C30.2091 90 32 91.7909 32 94V151C32 153.209 30.2091 155 28 155H0V90Z"
            fill="white"
            fillOpacity={fillOpacity}
          />
          <rect
            className={classNameWithTheme}
            x="46"
            y="92.5"
            width="156"
            height="14"
            rx="2"
            fill="white"
            fillOpacity={fillOpacity}
          />
          <rect
            className={classNameWithTheme}
            x="46"
            y="112.5"
            width="205"
            height="12"
            rx="2"
            fill="white"
            fillOpacity={fillOpacity}
          />
          <rect
            className={classNameWithTheme}
            x="46"
            y="127.5"
            width="205"
            height="12"
            rx="2"
            fill="white"
            fillOpacity={fillOpacity}
          />
          <rect
            className={classNameWithTheme}
            x="46"
            y="142.5"
            width="171"
            height="12"
            rx="2"
            fill="white"
            fillOpacity={fillOpacity}
          />
          <rect
            className={classNameWithTheme}
            x="268"
            y="100.5"
            width="60"
            height="44"
            rx="4"
            fill="white"
            fillOpacity={fillOpacity}
          />
          <path
            d="M0 164H340V242C340 243.657 338.657 245 337 245H2.99999C1.34314 245 0 243.657 0 242V164Z"
            fill={borderPathFillColor}
          />
          <path
            className={classNameWithTheme}
            d="M0 172H28C30.2091 172 32 173.791 32 176V233C32 235.209 30.2091 237 28 237H0V172Z"
            fill="white"
            fillOpacity={fillOpacity}
          />
          <rect
            className={classNameWithTheme}
            x="46"
            y="174.5"
            width="156"
            height="14"
            rx="2"
            fill="white"
            fillOpacity={fillOpacity}
          />
          <rect
            className={classNameWithTheme}
            x="46"
            y="194.5"
            width="205"
            height="12"
            rx="2"
            fill="white"
            fillOpacity={fillOpacity}
          />
          <rect
            className={classNameWithTheme}
            x="46"
            y="209.5"
            width="205"
            height="12"
            rx="2"
            fill="white"
            fillOpacity={fillOpacity}
          />
          <rect
            className={classNameWithTheme}
            x="46"
            y="224.5"
            width="171"
            height="12"
            rx="2"
            fill="white"
            fillOpacity={fillOpacity}
          />
          <rect
            className={classNameWithTheme}
            x="268"
            y="182.5"
            width="60"
            height="44"
            rx="4"
            fill="white"
            fillOpacity={fillOpacity}
          />
        </SVG>
      </CornerBorder>
    </Container>
  );
};
