import { get } from "lodash";
import type { PotentialReturnUtils, ProbableValueList } from "../generalTypes";
import { calculateProbableValue } from "../generalUtils";

export const quinella = ({
  betSelections,
  betCombosHash,
  minWagerAmount,
  betAmount
}: PotentialReturnUtils): ProbableValueList => {
  const probableValueList: ProbableValueList = [];

  const first = get(betSelections, "[0][0]", "");
  const second = get(betSelections, "[0][1]", "");
  if (first && second) {
    const payout = betCombosHash[`${first}-${second}`];

    try {
      if (typeof payout === "string") {
        if (payout !== "0.0") {
          probableValueList.push(
            calculateProbableValue(+payout, minWagerAmount, betAmount)
          );
        }
      } else {
        throw new Error("NaN");
      }
    } catch (e) {
      return [];
    }
  }

  return probableValueList;
};

export default quinella;
