import mediator from "@tvg/mediator";
import { ParlayCardCTAEventProps } from "@urp/amplitude/src/modules/sameRaceParlay/types";

export const parlayCardCTAEvent = ({
  raceNumber,
  trackName,
  position,
  linkUrl,
  linkText
}: ParlayCardCTAEventProps) => {
  mediator.base.dispatch({
    type: "SRP:PARLAY_CARD_CTA_CLICKED",
    payload: {
      raceNumber,
      trackName,
      position,
      linkUrl,
      linkText
    }
  });
};
