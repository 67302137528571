import styled, { css } from "styled-components";
import { breakpoints } from "@tvg/design-system";
import { LoadingProps } from "../types";

export const ScrollableContainer = styled.div`
  background-color: transparent;
  width: 100%;
  display: flex;
  gap: var(--fd-space-space-2);
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scroll-padding-left: var(--fd-space-space-2);

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Container = styled.div<Pick<LoadingProps, "isDarkMode">>`
  ${({ isDarkMode }) =>
    isDarkMode
      ? css`
          background-color: var(--fd-colors-core-grey-d-4);
        `
      : css`
          background-color: transparent;
        `}

  @media screen and ${breakpoints.tablet.min.sm} {
    padding: var(--fd-space-space-4);
  }
`;

export const Element = styled.div`
  scroll-snap-align: start;
  @media screen and ${breakpoints.tablet.max.sm} {
    &:first-of-type {
      padding-left: var(--fd-space-space-4);
    }

    &:last-of-type {
      padding-right: var(--fd-space-space-4);
    }
  }
`;

export const AboveHeaderBlock = styled.div`
  margin-left: var(--fd-space-space-4);
  padding-bottom: var(--fd-space-space-4);
`;
