import { gql } from "@apollo/client";
import { RACE } from "../fragments/Race";

export const GET_RACES_QUERY = gql`
  query races($tvgRaceIds: [Long]) {
    races(tvgRaceIds: $tvgRaceIds) {
      ...race
    }
  }
  ${RACE}
`;
