import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  useShowQuickPicksModule,
  useSameRaceParlayModule
} from "@urp/amplitude-experiments";
import { getHomepageTemplates } from "../store/selectors";
import { MobileTemplate } from "../types";

const useHomepageComponents = (selectedTemplate: string) => {
  const templates = useSelector(getHomepageTemplates);
  const isQuickPicksModuleActive = useShowQuickPicksModule();
  const isSRPModuleActive = useSameRaceParlayModule();

  const inactiveModules = useMemo(
    () => ({
      QuickPicks: !isQuickPicksModuleActive,
      SameRaceParlay: !isSRPModuleActive
    }),
    [isQuickPicksModuleActive, isSRPModuleActive]
  );

  const {
    filteredMainComponents: mainComponents,
    filteredBottomComponents: bottomComponents
  } = useMemo(() => {
    const components = templates?.[
      selectedTemplate
    ] as unknown as MobileTemplate;

    if (!components || !components.main) {
      return {
        mainComponents: [],
        bottomComponents: []
      };
    }

    const filteredMainComponents = components.main.filter(
      (componentObj) =>
        !inactiveModules[
          componentObj.componentName as keyof typeof inactiveModules
        ]
    );

    const filteredBottomComponents = components.bottom;

    return { filteredMainComponents, filteredBottomComponents };
  }, [selectedTemplate, JSON.stringify(templates), inactiveModules]);

  return { mainComponents, bottomComponents };
};

export default useHomepageComponents;
