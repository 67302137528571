import React from "react";
import { connect } from "react-redux";
import parseJSONCapiMessage from "@tvg/utils/capiUtils";
import { Mtp } from "@tvg/design-system";
import { Button, Header, Paragraph } from "@tvg/design-system/web";
import NumberFire from "@fdr/static-ui/SvgImages/NumberFire";
import SameRaceParlay from "@fdr/static-ui/SvgImages/SameRaceParlay";
import formatCurrency from "@tvg/formatter/currency";
import { RaceStatusEnum, Runner } from "@tvg/ts-types/Race";
import SaddleLabel from "../SaddleLabel";
import {
  CardWrapper,
  CardHeader,
  SrpMtpContainer,
  MtpContainer,
  CardBody,
  BetHeader,
  BetDetails,
  BetAmountContainer,
  NumberFireContainer,
  ButtonContainer,
  ButtonWrapper,
  ButtonText,
  RunnersContainer
} from "./styled-components";

interface SameRaceParlayCardProps {
  srpCardMessages: { [key: string]: string };
  qaLabel?: string;
  trackName: string;
  raceNumber: string;
  mtp: number;
  raceStatus: RaceStatusEnum;
  racePostTime: string;
  suggestedRunners: Runner[];
  wagerAmount: number;
  betTotal: string;
  betType: string;
  approxPay: string;
  onHeaderPress: () => void;
  onEditButtonPress: () => void;
  onPlaceBetButtonPress: () => void;
}

const SameRaceParlayCard = ({
  srpCardMessages,
  qaLabel = "srpCard",
  trackName,
  raceNumber,
  mtp,
  raceStatus,
  racePostTime,
  suggestedRunners,
  wagerAmount,
  betTotal,
  betType,
  approxPay,
  onHeaderPress,
  onEditButtonPress,
  onPlaceBetButtonPress
}: SameRaceParlayCardProps) => (
  <CardWrapper data-qa-label={qaLabel}>
    <CardHeader
      data-qa-label={`${qaLabel}-headerContainer`}
      onClick={onHeaderPress}
    >
      <Header tag="h3" color="--fd-colors-content-on-dark" fontSize="14px">
        {`${trackName} R${raceNumber}`}
      </Header>
      <SrpMtpContainer>
        <SameRaceParlay />
        <MtpContainer>
          <Mtp
            mtp={mtp}
            status={raceStatus}
            postTime={racePostTime}
            fontFamily="medium"
            fontSize="s"
            hasFullText
            isCollapsed
          />
        </MtpContainer>
      </SrpMtpContainer>
    </CardHeader>
    <CardBody>
      <BetHeader>
        <BetAmountContainer>
          <Paragraph qaLabel={`${qaLabel}-betType`} fontFamily="regular">
            {`${formatCurrency(wagerAmount)} ${betType}`}
          </Paragraph>
          <Paragraph
            qaLabel={`${qaLabel}-betTotal`}
            fontFamily="condensedRegular"
            fontSize="12px"
            mt="2px"
            textTransform="uppercase"
            color="--fd-colors-content-subtle"
          >
            {`${formatCurrency(+betTotal)} ${srpCardMessages.ticket}`}
          </Paragraph>
        </BetAmountContainer>
        <NumberFireContainer>
          <Paragraph
            qaLabel={`${qaLabel}-poweredBy`}
            fontSize="10px"
            color="--fd-colors-content-subtle"
          >
            {srpCardMessages.poweredBy}
          </Paragraph>
          <NumberFire />
        </NumberFireContainer>
      </BetHeader>
      <BetDetails>
        <Paragraph qaLabel={`${qaLabel}-finishOrder`}>
          {srpCardMessages.finishOrder}
        </Paragraph>
        <RunnersContainer
          data-qa-label={`${qaLabel}-runnersContainer`}
          onClick={onEditButtonPress}
        >
          {suggestedRunners?.map((runner) => (
            <SaddleLabel
              key={`${trackName}-${raceNumber}-${runner.horseName}`}
              size="s"
              number={runner.runnerId}
              horseName={runner.horseName}
              mt="var(--fd-space-space-3)"
            />
          ))}
        </RunnersContainer>
      </BetDetails>
      <ButtonContainer>
        <ButtonWrapper>
          <Button
            qaLabel={`${qaLabel}-editButton`}
            variant="secondary"
            onClick={onEditButtonPress}
          >
            {srpCardMessages.edit}
          </Button>
        </ButtonWrapper>
        <ButtonWrapper isStretched isLast>
          <Button
            qaLabel={`${qaLabel}-placeBetButton`}
            variant="betting"
            onClick={onPlaceBetButtonPress}
            isStretched
          >
            <ButtonText>
              <Paragraph
                qaLabel={`${qaLabel}-placeBetButton-text`}
                color="--fd-colors-content-on-dark"
                textAlign="center"
              >
                {`Place ${formatCurrency(+betTotal)} ${srpCardMessages.bet}`}
              </Paragraph>
              <Paragraph
                qaLabel={`${qaLabel}-placeBetButton-approxPayout`}
                color="--fd-colors-content-on-dark"
                textAlign="center"
                textTransform="uppercase"
                fontFamily="condensedRegular"
                fontSize="12px"
                whiteSpace="nowrap"
              >
                {`${srpCardMessages.approxPay} ${approxPay}`}
              </Paragraph>
            </ButtonText>
          </Button>
        </ButtonWrapper>
      </ButtonContainer>
    </CardBody>
  </CardWrapper>
);

export default connect((store) => ({
  srpCardMessages: parseJSONCapiMessage(
    store,
    "capi.messages.sameRaceParlayCard"
  )
}))(SameRaceParlayCard);
