import type { PotentialReturnUtils, ProbableValueList } from "../generalTypes";
import { calculateProbableValue } from "../generalUtils";

export const win = ({
  betSelections,
  betCombosHash,
  minWagerAmount,
  betAmount
}: PotentialReturnUtils): ProbableValueList => {
  const probableValueList: ProbableValueList = [];

  try {
    betSelections[0].forEach((betSelection) => {
      const payout = betCombosHash[betSelection];

      if (typeof payout === "string") {
        if (payout !== "0.0") {
          probableValueList.push(
            calculateProbableValue(+payout, minWagerAmount, betAmount)
          );
        }
      } else {
        throw new Error("NaN");
      }
    });
  } catch (e) {
    return [];
  }

  return probableValueList;
};

export default win;
