import React, { PureComponent } from "react";
import styled from "styled-components";

const SVG = styled.svg`
  display: block;
`;

export default class SameRaceParlay extends PureComponent {
  render() {
    return (
      <SVG
        width="32"
        height="16"
        viewBox="0 0 32 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_900_4226)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.35025 0.941162H28.7589C29.7985 0.941162 30.6412 1.78392 30.6412 2.82352C30.6412 2.94844 30.6288 3.07306 30.6041 3.19553L28.5169 13.5485C28.3397 14.427 27.5678 15.0588 26.6716 15.0588H3.24466C2.20506 15.0588 1.3623 14.2161 1.3623 13.1765C1.3623 13.0505 1.37496 12.9248 1.40007 12.8013L3.50566 2.44836C3.68404 1.57133 4.45527 0.941162 5.35025 0.941162Z"
            fill="#005D23"
          />
          <path
            d="M28.7586 0H5.35002C4.00755 0 2.8507 0.945254 2.58314 2.2608L0.477543 12.6137C0.439875 12.7989 0.420898 12.9875 0.420898 13.1765C0.420898 14.7359 1.68504 16 3.24443 16H26.6714C28.0157 16 29.1736 15.0523 29.4392 13.7345L31.5265 3.38155C31.5635 3.19785 31.5822 3.01092 31.5822 2.82353C31.5822 1.26414 30.318 0 28.7586 0ZM5.35002 1.88235H28.7586C29.2784 1.88235 29.6998 2.30373 29.6998 2.82353C29.6998 2.88599 29.6936 2.9483 29.6812 3.00954L27.594 13.3625C27.5055 13.8017 27.1195 14.1176 26.6714 14.1176H3.24443C2.72463 14.1176 2.30325 13.6963 2.30325 13.1765C2.30325 13.1135 2.30958 13.0506 2.32213 12.9889L4.42773 2.63595C4.51691 2.19744 4.90253 1.88235 5.35002 1.88235Z"
            fill="#005D23"
          />
          <path
            d="M28.7589 0.941162C29.7985 0.941162 30.6412 1.78392 30.6412 2.82352C30.6412 2.94844 30.6288 3.07306 30.6041 3.19553L28.5169 13.5485C28.3397 14.427 27.5678 15.0588 26.6716 15.0588H3.24466C2.20506 15.0588 1.3623 14.2161 1.3623 13.1765C1.3623 13.0505 1.37496 12.9248 1.40007 12.8013L3.50566 2.44836C3.68404 1.57133 4.45527 0.941162 5.35025 0.941162H28.7589ZM28.7589 1.88234H5.35025C4.94005 1.88234 4.58184 2.1471 4.45629 2.52899L4.42796 2.63594L2.32236 12.9889C2.30981 13.0506 2.30348 13.1135 2.30348 13.1765C2.30348 13.6591 2.66681 14.0569 3.1349 14.1113L3.24466 14.1176H26.6716C27.0824 14.1176 27.4409 13.8522 27.566 13.4696L27.5942 13.3625L29.6815 3.00952C29.6938 2.94829 29.7 2.88598 29.7 2.82352C29.7 2.34085 29.3367 1.94304 28.8686 1.88867L28.7589 1.88234Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.91694 5.99028L8.71915 6.9227C8.5402 7.77978 9.05821 8.18477 9.52913 8.31663L11.3186 8.80639C11.4693 8.84406 11.5258 8.91941 11.4882 9.07953L11.3092 9.89893C11.2715 10.0685 11.1774 10.1438 11.0172 10.1438H9.64215C9.47262 10.1438 9.40669 10.0685 9.44437 9.89893L9.55739 9.36208H8.14462L8.05044 9.81416C7.84323 10.8031 8.27648 11.34 9.31251 11.34H11.1397C11.9779 11.34 12.5148 10.8879 12.6937 10.0496L12.9386 8.89116C13.0799 8.21303 12.7973 7.77036 12.0344 7.56316L10.3109 7.09223C10.1508 7.04514 10.1225 6.97921 10.1602 6.8191L10.3014 6.13155C10.3391 5.97144 10.4239 5.89609 10.5934 5.89609H11.8461C12.0156 5.89609 12.0815 5.97144 12.0439 6.14097L11.9214 6.73433H13.3342L13.4378 6.22574C13.645 5.22738 13.2212 4.69995 12.1851 4.69995H10.4898C9.65157 4.69995 9.09589 5.15204 8.91694 5.99028ZM16.5372 11.3023L16.3018 8.83468H15.3788L14.8513 11.3023H13.4197L14.8137 4.73766H17.8182C18.873 4.73766 19.3157 5.31219 19.0896 6.35763L18.8353 7.54436C18.6941 8.21307 18.3267 8.6369 17.7616 8.77817L18.0725 11.3023H16.5372ZM17.5073 5.95264H15.9816L15.6331 7.6197H17.1589C17.319 7.6197 17.4132 7.53494 17.4508 7.37483L17.7051 6.19752C17.7428 6.02799 17.6769 5.95264 17.5073 5.95264ZM22.8728 8.97596H20.9609L20.4711 11.3023H19.0395L20.4335 4.73766H23.4474C24.4928 4.73766 24.9355 5.31219 24.7094 6.35763L24.4269 7.68563C24.2479 8.52387 23.7111 8.97596 22.8728 8.97596ZM23.1271 5.97148H21.6013L21.2246 7.74214H22.7504C22.9105 7.74214 23.0047 7.6668 23.0424 7.49726L23.3155 6.21636C23.3532 6.04682 23.2967 5.97148 23.1271 5.97148Z"
            fill="#FFDC2E"
          />
        </g>
        <defs>
          <clipPath id="clip0_900_4226">
            <rect width="32" height="16" fill="white" />
          </clipPath>
        </defs>
      </SVG>
    );
  }
}
