import React from "react";
import TopPools from "@urp/top-pools";
import SeoFooter from "@tvg/atomic-ui/_static/SeoFooter";
import PromosWidgetComponent from "@urp/promos-widget";
import tvgConf from "@tvg/conf";
import PreviousWinnersComponent from "@urp/previous-winners";
import { TipsAndPicks } from "@urp/tips-picks";
import { TalentPickList } from "@urp/talent-picks";
import QuickLinks from "@urp/quick-links";
import { TopRaces } from "@urp/popular-races";
import UpcomingRaces from "@urp/upcoming-races";
import TopTracks from "@urp/top-tracks";
import { Device } from "@tvg/ts-types/Device";
import { Racing101Section } from "@urp/education-hub";
import { SameRaceParlayModule } from "@urp/same-race-parlay";
import { QuickPicksModule } from "@urp/quick-picks";
import { ComponentMapping } from "./types";

const handleDevice =
  tvgConf().device === "desktop" ? Device.DESKTOP : Device.MOBILE;

const componentsMapping: ComponentMapping = {
  QuickLinks: {
    component: () => (
      <QuickLinks
        useQuickLinksExpanded
        device={handleDevice}
        shouldUpdate
        name="QuickLinks"
        qaLabel="quick-links-section"
      />
    )
  },
  UpcomingRaces: {
    component: (props) => (
      <UpcomingRaces
        title={props.title}
        subtitle={props.description}
        name="UpcomingRaces"
        qaLabel="upcoming-races-section"
      />
    )
  },
  TopRaces: {
    component: (props) => (
      <TopRaces
        title={props.title}
        subtitle={props.description}
        name="TopRaces"
        qaLabel="top-races-section"
      />
    )
  },
  QuickPicks: {
    component: (props) => (
      <QuickPicksModule title={props.title} qaLabel="quick-picks-section" />
    )
  },
  TipsAndPicks: {
    component: (props) => (
      <TipsAndPicks
        data-qa-label="tips-picks"
        title={props.title}
        name="TipsAndPicks"
        qaLabel="tips-picks-section"
      />
    )
  },
  TopPools: {
    component: (props) => (
      <TopPools
        shouldUpdate
        device={handleDevice}
        title={props.title}
        name="TopPools"
        qaLabel="top-pools-section"
      />
    )
  },
  TopTracks: {
    component: (props) => (
      <TopTracks
        title={props.title}
        name="TopTracks"
        qaLabel="top-tracks-section"
      />
    )
  },
  SameRaceParlay: {
    component: (props) => <SameRaceParlayModule title={props.title} />
  },
  PromosWidget: {
    component: (props) => (
      <PromosWidgetComponent
        title={props.title}
        name="PromosWidget"
        qaLabel="promo-widget-section"
      />
    )
  },
  PreviousWinners: {
    component: (props) => (
      <PreviousWinnersComponent
        device={handleDevice}
        title={props.title}
        name="PreviousWinners"
        qaLabel="previous-winners-section"
      />
    )
  },
  TalentPicks: {
    component: (props) => (
      <TalentPickList
        onHomePage
        title={props.title}
        name="TalentPicks"
        seeAllLink={{
          url: "/handicapping/talent-picks/",
          label: "See all"
        }}
        qaLabel="talent-picks-section"
      />
    )
  },
  FeaturedSection: {
    component: (props) => (
      <Racing101Section
        title={props.title}
        device={handleDevice}
        qaLabel="horse-racing-101-section"
      />
    )
  },
  SEOFooter: {
    component: (props) => (
      <SeoFooter
        message={props.text}
        title={props.title}
        device={handleDevice}
      />
    )
  }
};

export default componentsMapping;
