import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { GET_QUICK_PICKS_QUERY } from "../graphql/queries/QuickPicksQuery";
import { apolloOptionsQuickPicks } from "../graphql/options.graph";

interface UseQuickPicksQueryParams {
  tvgRaceIds?: number[];
}

const useQuickPicksQuery = ({ tvgRaceIds }: UseQuickPicksQueryParams) => {
  const apolloOptions = useMemo(
    () => apolloOptionsQuickPicks({ tvgRaceIds }),
    tvgRaceIds
  );

  const { loading, data } = useQuery(GET_QUICK_PICKS_QUERY, apolloOptions);

  return useMemo(
    () => ({
      loading,
      quickPicksData: data?.quickPicks
    }),
    [loading, data]
  );
};
export default useQuickPicksQuery;
