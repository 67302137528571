import { breakpoints, useMediaQuery } from "@tvg/design-system";
import { Carousel } from "@tvg/design-system/web";
import Header from "@tvg/design-system/web/components/Header";
import buildRaceUrl, { addParamToURL } from "@tvg/formatter/url";
import { setModule } from "@urp/shared-redux/BetSource/actions";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { quickPickCardCTAEvent } from "../../amplitude";
import useRacesQuery from "../../hooks/useRacesQuery";
import { QPRace, QuickPicksDataType } from "../../types";
import { getFilteredMergedData } from "../../utils";
import { QuickPicksEnum } from "../../utils/constants";
import LoadingContainer from "../LoadingContainer";
import RunnerInfo from "../RunnerInfo";
import {
  CardContainer,
  Container,
  HeaderContainer,
  RunnerInfoContainer
} from "./styled-components";
import {
  OnClickProps,
  RaceCardContainerProps,
  RenderRunnerInfoProps
} from "./types";

const RaceCardContainer = ({
  qaLabel = "quickPicks",
  title,
  raceIds,
  quickPicksData
}: RaceCardContainerProps) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);
  const navigate = useNavigate();

  const { allRacesByTvgRaceIds, loading: loadingAllRacesByTvgRaceIds } =
    useRacesQuery({ tvgRaceIds: raceIds });

  if (loadingAllRacesByTvgRaceIds) {
    return <LoadingContainer />;
  }

  if (!allRacesByTvgRaceIds?.length) {
    return null;
  }

  const handleOnClick = ({
    index,
    e,
    number,
    trackCode,
    trackName,
    runnerId,
    linkText,
    wagerType
  }: OnClickProps) => {
    e.stopPropagation();

    dispatch(setModule("quick_picks"));

    let wagerUrl: string = buildRaceUrl(trackCode, trackName, number);

    if (wagerType) {
      wagerUrl = addParamToURL(wagerUrl, "wt", wagerType);
    }

    if (runnerId) {
      wagerUrl = addParamToURL(wagerUrl, "selectedRunner", runnerId as string);
    }

    quickPickCardCTAEvent({
      raceNumber: number,
      trackName,
      position: index + 1,
      linkUrl: wagerUrl,
      linkText
    });
    navigate(wagerUrl);
  };

  const renderRunnerInfo = ({
    quickPicks,
    number,
    trackCode,
    trackName,
    index
  }: RenderRunnerInfoProps) => {
    if (!quickPicks.length) {
      return null;
    }

    return quickPicks.map((quickPick) => {
      const { matchedBettingInterests, wagerTypeAbbreviation } = quickPick;
      if (!matchedBettingInterests?.length) return null;

      const runner = matchedBettingInterests[0];
      const runnerDetails = runner?.runners[0];

      const {
        runnerId,
        scratched: isScratched,
        timeform,
        horseName
      } = runnerDetails;
      const { currentOdds, biNumber, saddleColor, numberColor } = runner;
      const { tag, summary } = quickPick;
      const { numerator, denominator } = currentOdds;

      const formattedDenominator = denominator ?? 1;
      const formattedOdds = `${numerator}/${formattedDenominator}`;

      return (
        <RunnerInfo
          handleOnClick={(e) => {
            e.stopPropagation();
            handleOnClick({
              index,
              e,
              number,
              trackCode,
              trackName,
              runnerId,
              linkText: formattedOdds,
              wagerType: wagerTypeAbbreviation
            });
          }}
          formattedOdds={formattedOdds}
          tag={tag}
          summary={summary}
          isScratched={isScratched}
          biNumber={biNumber}
          saddleColor={saddleColor}
          numberColor={numberColor}
          timeform={timeform}
          horseName={horseName}
        />
      );
    });
  };

  const renderRaceCard = (qpData: QuickPicksDataType[], allRaces: QPRace[]) =>
    getFilteredMergedData(qpData, allRaces)?.map(
      (raceCardData: QuickPicksDataType, index: number) => {
        const { raceDetails, quickPicks } = raceCardData;

        if (!raceDetails) return null;

        const { number, trackCode, trackName } = raceDetails;
        return (
          <CardContainer key={raceCardData.tvgRaceId}>
            <HeaderContainer>
              <Header
                race={raceDetails}
                qaLabel="qp-header"
                isRaceNumberOnSameLine
                onClick={(e) => {
                  handleOnClick({
                    index,
                    // @ts-ignore
                    e,
                    number,
                    trackCode,
                    trackName,
                    linkText: QuickPicksEnum.HEADER_LINK_TEXT
                  });
                }}
              />
            </HeaderContainer>
            <RunnerInfoContainer>
              {renderRunnerInfo({
                quickPicks,
                number,
                trackCode,
                trackName,
                index
              })}
            </RunnerInfoContainer>
          </CardContainer>
        );
      }
    );

  return (
    <Container data-qa-label={qaLabel}>
      <Carousel
        qaLabel={`${title}-carousel`}
        slidesToScroll="auto"
        dragFree={isMobile}
        displayHeader={false}
        slideSize={341}
        displayNavButtons={false}
        items={renderRaceCard(quickPicksData, allRacesByTvgRaceIds)}
      />
    </Container>
  );
};

export default RaceCardContainer;
