import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Banner } from "@urp/banners/src";
import { BannersConfigContext } from "@urp/banners/src/types";
import { PreferencesTabEnum } from "@tvg/sh-lib-preferences/utils/types";
import {
  openPreferencesModalAction,
  setSelectedTab
} from "@tvg/sh-lib-preferences/redux/actions";
import { getShowHPTemplateSelector } from "@tvg/sh-lib-preferences/redux/selectors";
import { setHPTemplateSelectorVisibility } from "@tvg/pref/src/components/HomepageConfig/utils";
import PreferencesModal from "@tvg/pref/src/components/PreferencesModal";
import { getIsLogged, getAccountNumber } from "@urp/store-selectors";
import { getEnableHomepagePillsNav } from "../../store/selectors";
import useSections from "../../hooks/useSections";
import useHomepageComponents from "../../hooks/useHomepageComponents";
import { renderComponent } from "../../utils/renderComponents";
import HomePageMask from "../../loadings/HomepageMask";
import HPTemplateSelector from "../HPTemplateSelector";
import { ComponentSection, PillsNav } from "..";
import {
  StandaloneWrapper,
  TemplateSelectorBackground,
  TemplateSelectorContainer,
  Bottom
} from "./styled-components";

const MobileHomepage = ({
  selectedTemplate,
  isHomepageConfigurable
}: {
  selectedTemplate: string;
  isHomepageConfigurable: boolean;
}) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLogged);
  const accountNumber = useSelector(getAccountNumber);
  const showHPTemplateSelector = useSelector(getShowHPTemplateSelector);
  const enablePillsNav = useSelector(getEnableHomepagePillsNav);

  const { mainComponents, bottomComponents } =
    useHomepageComponents(selectedTemplate);

  const componentsOrder = (mainComponents ?? []).map((component) => ({
    name: component.componentName,
    title: component.title
  }));

  const {
    pills,
    handleOnSectionVisibilityChanges,
    isLoading: areSectionsLoading,
    pillsDistanceFromTop
  } = useSections({
    enablePillsNav,
    componentsOrder: componentsOrder ?? []
  });

  const renderMainSection = useCallback(
    () =>
      mainComponents?.map((componentObj) => {
        const component = renderComponent(componentObj);
        if (!component) return null;

        const title =
          component.props?.title || component.props?.componentSectionTitle;

        return (
          <StandaloneWrapper
            key={`main-component-${componentObj.componentName}`}
          >
            <ComponentSection
              qaLabel={
                component.props?.qaLabel ??
                `${componentObj.componentName}-section`
              }
              componentName={componentObj.componentName}
              hasHeaderSpace={true}
              hasBackground={false}
              title={title}
              subtitle={component.props?.subtitle}
              onSectionVisibilityChanges={handleOnSectionVisibilityChanges}
              seeAllLink={component.props?.seeAllLink}
            >
              {component}
            </ComponentSection>
          </StandaloneWrapper>
        );
      }),
    [mainComponents, handleOnSectionVisibilityChanges]
  );

  const hasComponentLoaded =
    Array.isArray(mainComponents) && mainComponents.length > 0;

  return hasComponentLoaded ? (
    <>
      <TemplateSelectorContainer>
        <TemplateSelectorBackground>
          {isHomepageConfigurable && isLoggedIn && (
            <>
              <PreferencesModal />
              {showHPTemplateSelector && (
                <HPTemplateSelector
                  isMobile
                  onSettingsClick={() => {
                    dispatch(openPreferencesModalAction());
                    dispatch(
                      setSelectedTab(PreferencesTabEnum.HOMEPAGE_CONFIG)
                    );
                  }}
                  onDismiss={() =>
                    setHPTemplateSelectorVisibility(
                      accountNumber,
                      false,
                      dispatch
                    )
                  }
                />
              )}
            </>
          )}
        </TemplateSelectorBackground>
      </TemplateSelectorContainer>
      <Banner context={BannersConfigContext.HOME_PAGE} />
      {enablePillsNav && (
        <PillsNav
          pills={pills}
          isLoading={areSectionsLoading}
          pillsDistanceFromTop={pillsDistanceFromTop}
        />
      )}
      {renderMainSection()}
      <Bottom data-qa-label="mobile-seo-footer">
        {bottomComponents?.map(renderComponent)}
      </Bottom>
    </>
  ) : (
    <HomePageMask />
  );
};

export default React.memo(MobileHomepage);
