import styled, { css, keyframes } from "styled-components";
import color from "@fdr/static-ui/ColorPalette";
import { LoadingProps } from "../types";

const darkModePulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "400")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "400")};
  }
`;

const lightModePulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 320px;
  overflow: hidden;
  background-color: transparent;
  margin-bottom: var(--fd-space-space-3);
  padding-top: 6px;
  border-radius: var(--fd-radii-border-radius-020);
  border: 1px solid var(--fd-colors-border-subtle);
`;

const getBorderStyle = css<Pick<LoadingProps, "isDarkMode">>`
  ${({ isDarkMode }) =>
    isDarkMode
      ? css`
          border-bottom: 1px solid var(--fd-colors-core-grey-d-1);
        `
      : css`
          border-bottom: 1px solid var(--fd-colors-core-navy-l-4);
        `}
`;

export const CornerBorder = styled.div<Pick<LoadingProps, "isDarkMode">>`
  ${getBorderStyle}
  border-radius: 4px;
`;

export const BottomBorder = styled.div<Pick<LoadingProps, "isDarkMode">>`
  ${getBorderStyle}
`;

export const SVG = styled.svg`
  display: block;

  rect.lightPulse,
  path.lightPulse {
    animation: ${lightModePulseAnimation} 1.25s infinite;
  }

  rect.darkPulse,
  path.darkPulse {
    animation: ${darkModePulseAnimation} 1.25s infinite;
  }
`;

export const AboveHeaderBlock = styled.div`
  margin-left: -16px;
  padding-bottom: 16px;
  padding-top: 16px;
`;
