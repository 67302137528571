import {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
  RefObject,
  useCallback
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { throttle, debounce } from "lodash";
import mediator from "@tvg/mediator";
import { bannerProps } from "@tvg/sh-utils/fixedBanner";
import parseJSONCapiMessage from "@tvg/utils/capiUtils";
import { getGeolocationStatus } from "@tvg/sh-geolocation/src/redux/selectors";
import {
  getIsVerified,
  getIsTermsAccepted,
  getIsLogged
} from "@urp/store-selectors/users";
import { getHasCrossNavigation } from "../store/selectors";
import { Section, ComponentOrder } from "../types";

interface Props {
  enablePillsNav: boolean;
  componentsOrder: ComponentOrder[];
}

export const LOADING_TIMEOUT_DELAY = 3000;
export const PILLS_HEIGHT = 48;
export const HEADER_HEIGHT = 48;
export const CROSS_NAV_HEIGHT = 58;
export const FIXED_BANNER_HEIGHT = 34;

export const handleSectionClick = throttle(
  (
    sectionName: string,
    element: HTMLElement | null,
    setSelectedSection: Dispatch<SetStateAction<string>>,
    pillsDistanceFromTop: number,
    isScrollingThroughPills
  ) => {
    if (window && element) {
      const yOffset = pillsDistanceFromTop + PILLS_HEIGHT;
      const elementDistanceToTop =
        window.scrollY + (element?.getBoundingClientRect().top ?? 0);
      const y = elementDistanceToTop - yOffset;

      isScrollingThroughPills.current = true;

      window.scrollTo({ top: y, behavior: "smooth" });
      setSelectedSection(sectionName);

      // This could be enhanced with a "promise-based scrollTo" abstraction
      // Since scrollTo fn with "smooth" behavior is asynchronous, it becomes difficult to determine when it finishes
      // thus the need for the timeout
      setTimeout(() => {
        isScrollingThroughPills.current = false;
      }, 700);
    }
  },
  750,
  { trailing: false }
);

const sendAnalyticEvt = (sectionTitle: string) => {
  mediator.base.dispatch({
    type: "HEADER_PILL_CLICKED",
    payload: {
      eventLabel: sectionTitle
    }
  });
};

const useSections = ({ enablePillsNav, componentsOrder }: Props) => {
  let observer = undefined as IntersectionObserver | undefined;
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSection, setSelectedSection] = useState<string>("");
  const [sections, setSections] = useState<Section[]>([]);
  const [pillsDistanceFromTop, setPillsDistanceFromTop] =
    useState(HEADER_HEIGHT);
  const isScrollingThroughPills = useRef(false);
  const dispatch = useDispatch();

  const hasCrossNavigation = useSelector(getHasCrossNavigation);
  const isVerified = useSelector(getIsVerified);
  const isTermsAccepted = useSelector(getIsTermsAccepted);
  const isLogged = useSelector(getIsLogged);
  const geolocationStatus = useSelector(getGeolocationStatus);
  const geolocationBannerMsg = useSelector((store) =>
    parseJSONCapiMessage(store, "capi.messages.geoLocationBannerMsg")
  );
  const accountCompliantBannerMsg = useSelector((store) =>
    parseJSONCapiMessage(store, "capi.messages.accountCompliantBannerMsg")
  );

  const hasFixedBanner = Boolean(
    bannerProps(
      dispatch,
      {
        isVerified,
        isLogged,
        isTermsAccepted
      },
      accountCompliantBannerMsg,
      geolocationBannerMsg,
      geolocationStatus
    )
  );

  const sortOrder = (a: Section, b: Section) => {
    const indexA = componentsOrder.findIndex((item) => item.name === a.name);
    const indexB = componentsOrder.findIndex((item) => item.name === b.name);
    return indexA - indexB;
  };

  const pills = sections.sort(sortOrder).map(({ name, title, element }) => ({
    title,
    name,
    isActive: selectedSection === name,
    onClick: () => {
      handleSectionClick(
        name,
        element,
        setSelectedSection,
        pillsDistanceFromTop,
        isScrollingThroughPills
      );
      sendAnalyticEvt(title);
    }
  }));

  const updateSelectedSection = debounce((newSection) => {
    setSelectedSection(newSection);
  }, 100);

  useEffect(() => {
    if (!enablePillsNav) return;
    setPillsDistanceFromTop(
      HEADER_HEIGHT +
        (hasCrossNavigation ? CROSS_NAV_HEIGHT : 0) +
        (hasFixedBanner ? FIXED_BANNER_HEIGHT : 0)
    );
  }, [hasFixedBanner, hasCrossNavigation, enablePillsNav]);

  useEffect(() => {
    if (!enablePillsNav) return;
    if (sections?.length > 0 && !isLoading) {
      const [firstSection] = sections;
      // Update the titles to the most recent ones after loading
      setSections(
        sections.reduce((acc, curr) => {
          acc.push({
            ...curr,
            title:
              componentsOrder.find((c) => c.name === curr.name)?.title || ""
          });
          return acc;
        }, [] as Section[])
      );
      // Select first sections by default after loading
      setSelectedSection(firstSection.name);
    }
  }, [isLoading, enablePillsNav]);

  // Since we're inferring which sections are on the DOM based on a Mutation Observer (ComponentSection),
  // there's no way to know exactly when all the sections are available/present on the DOM, which is why there's a timeout.
  useEffect(() => {
    if (!enablePillsNav) return undefined;
    setIsLoading(true);
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, LOADING_TIMEOUT_DELAY);
    return () => clearTimeout(loadingTimeout);
  }, [isLogged, enablePillsNav]);

  useEffect(() => {
    if (!enablePillsNav) return undefined;
    if (sections.length > 1) {
      const windowInnerHeight = window && window.innerHeight;
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (!isScrollingThroughPills.current) {
              let extractedTab;
              if (
                !entry.isIntersecting &&
                entry.boundingClientRect.bottom < windowInnerHeight
              ) {
                extractedTab =
                  sections[
                    sections.findIndex(
                      ({ element }) => element === entry.target
                    ) + 1
                  ];
              }
              if (
                entry.isIntersecting &&
                entry.boundingClientRect.top <
                  pillsDistanceFromTop + PILLS_HEIGHT
              ) {
                extractedTab =
                  sections[
                    sections.findIndex(
                      ({ element }) => element === entry.target
                    )
                  ];
              }

              if (extractedTab?.name) {
                updateSelectedSection(extractedTab?.name);
              }
            }
          });
        },
        {
          threshold: 0,
          rootMargin: `-${pillsDistanceFromTop + PILLS_HEIGHT}px`
        }
      );

      sections.forEach(
        ({ element }) => element && observer && observer.observe(element)
      );
    }

    return () => {
      sections.forEach(
        ({ element }) => element && observer && observer.unobserve(element)
      );
    };
  }, [sections.length, isLoading, enablePillsNav]);

  const handleOnSectionVisibilityChanges = useCallback(
    (
      name: string,
      // eslint-disable-next-line @typescript-eslint/default-param-last
      title: string = "",
      sectionWrapperRef: RefObject<HTMLElement>,
      isComponentVisible: boolean
    ) => {
      if (!enablePillsNav) return;
      // Check if a section is already created to prevent duplications
      const isSectionAlreadyCreated = (secs: Section[]) =>
        secs.some((sec) => sec.name === name);

      // Criteria to filter the Sections object if an HTML section is removed from DOM
      const filterCriteria = (sec: Section) => sec.name !== name;

      if (isComponentVisible) {
        setSections((prev: Section[]) => {
          if (!isSectionAlreadyCreated(prev)) {
            return [
              ...prev,
              { element: sectionWrapperRef.current, name, title }
            ];
          }
          return prev;
        });
      } else {
        setSections((prev) => prev.filter(filterCriteria));
      }
    },
    [enablePillsNav]
  );

  return {
    pills,
    handleOnSectionVisibilityChanges,
    isLoading,
    sections,
    pillsDistanceFromTop
  };
};

export default useSections;
