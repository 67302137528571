import React from "react";
import {
  SVG,
  LoadingContainer,
  ScrollableContainer,
  CardContainer,
  DescriptionContainer
} from "./styled-components";

const LoadingCard = () => (
  <SVG
    width="301"
    height="271"
    viewBox="0 0 301 271"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1232_63450)">
      <rect width="301" height="271" rx="4" fill="#011638" />
      <rect width="301" height="61" fill="#011638" />
      <rect
        x="51.5"
        y="13.5"
        width="198"
        height="14"
        rx="1"
        fill="white"
        fillOpacity="0.1"
      />
      <rect
        x="75"
        y="31.5"
        width="32"
        height="17"
        rx="1"
        fill="white"
        fillOpacity="0.1"
      />
      <rect
        x="111"
        y="33"
        width="115"
        height="14"
        rx="1"
        fill="white"
        fillOpacity="0.1"
      />
      <g clipPath="url(#clip1_1232_63450)">
        <rect x="4" y="61" width="293" height="206" rx="2" fill="white" />
        <mask id="path-6-inside-1_1232_63450" fill="white">
          <path d="M4 61H297V113H4V61Z" />
        </mask>
        <path d="M4 61H297V113H4V61Z" fill="#F7FBFF" />
        <path
          d="M297 112H4V114H297V112Z"
          fill="#C6D3E1"
          mask="url(#path-6-inside-1_1232_63450)"
        />
        <rect
          x="16"
          y="72"
          width="117"
          height="13"
          rx="1"
          fill="#0A0A0A"
          fillOpacity="0.05"
        />
        <rect
          x="16"
          y="91"
          width="81"
          height="11"
          rx="1"
          fill="#0A0A0A"
          fillOpacity="0.05"
        />
        <rect
          x="209.825"
          y="71.5"
          width="75.1745"
          height="30"
          rx="1"
          fill="#0A0A0A"
          fillOpacity="0.05"
        />
        <rect
          width="293"
          height="154"
          transform="translate(4 113)"
          fill="white"
        />
        <rect
          x="16"
          y="124"
          width="199"
          height="14"
          rx="1"
          fill="#0A0A0A"
          fillOpacity="0.05"
        />
        <rect
          width="293"
          height="76"
          transform="translate(4 139)"
          fill="white"
        />
        <rect
          x="16"
          y="151"
          width="20"
          height="20"
          rx="1"
          fill="#0A0A0A"
          fillOpacity="0.05"
        />
        <rect
          x="44"
          y="154.5"
          width="114"
          height="14"
          rx="1"
          fill="#0A0A0A"
          fillOpacity="0.05"
        />
        <rect
          x="16"
          y="183"
          width="20"
          height="20"
          rx="1"
          fill="#0A0A0A"
          fillOpacity="0.05"
        />
        <rect
          x="44"
          y="186.5"
          width="114"
          height="14"
          rx="1"
          fill="#0A0A0A"
          fillOpacity="0.05"
        />
        <rect
          width="293"
          height="52"
          transform="translate(4 215)"
          fill="white"
        />
        <rect
          x="16"
          y="215"
          width="56"
          height="44"
          rx="4"
          fill="#0A0A0A"
          fillOpacity="0.05"
        />
        <rect
          x="80"
          y="215"
          width="205"
          height="44"
          rx="4"
          fill="#0A0A0A"
          fillOpacity="0.05"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1232_63450">
        <rect width="301" height="271" rx="4" fill="white" />
      </clipPath>
      <clipPath id="clip1_1232_63450">
        <rect x="4" y="61" width="293" height="206" rx="2" fill="white" />
      </clipPath>
    </defs>
  </SVG>
);

const LoadingDescription = () => (
  <SVG
    width="343"
    height="35"
    viewBox="0 0 343 35"
    fill="none"
    xmlns="http://www.w3.org/2000/sVG"
  >
    <rect
      y="3"
      width="343"
      height="14"
      rx="1"
      fill="#0A0A0A"
      fillOpacity="0.05"
    />
    <rect
      y="21"
      width="149"
      height="14"
      rx="1"
      fill="#0A0A0A"
      fillOpacity="0.05"
    />
  </SVG>
);

interface LoadingModuleProps {
  numberOfCards?: number;
}

const LoadingModule = ({ numberOfCards = 2 }: LoadingModuleProps) => (
  <LoadingContainer>
    <DescriptionContainer>
      <LoadingDescription />
    </DescriptionContainer>
    <ScrollableContainer>
      {[...Array(numberOfCards)].map((_, index) => (
        <CardContainer key={`card-empty-${index.toString()}`}>
          <LoadingCard />
        </CardContainer>
      ))}
    </ScrollableContainer>
  </LoadingContainer>
);

export default LoadingModule;
