import styled, { css } from "styled-components";

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: var(--fd-radii-border-radius-020);
  background-color: var(--fd-colors-background-promotional);
  width: 301px;
  height: 271px;
  flex-shrink: 0;
  scroll-snap-stop: always;
  scroll-snap-align: start;
  overflow: hidden;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  height: 64px;
  margin-top: var(--fd-space-space-1);
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const SrpMtpContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MtpContainer = styled.div`
  margin-left: var(--fd-space-space-1);
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--fd-colors-content-on-dark);
  height: 100%;
  width: auto;
  margin: var(--fd-space-space-1);
  border-radius: var(--fd-radii-border-radius-010);
  overflow: hidden;
`;

export const BetHeader = styled.div`
  display: flex;
  flex-direction: row;
  height: 52px;
  background-color: var(--fd-colors-background-layer);
  border-bottom: 1px solid var(--fd-colors-border-subtle);
  padding: 0 var(--fd-space-space-3);
  justify-content: space-between;
  align-items: center;
`;

export const BetAmountContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NumberFireContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const BetDetails = styled.div`
  padding: var(--fd-space-space-2) var(--fd-space-space-3) 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: auto;
  padding: 0 var(--fd-space-space-3) var(--fd-space-space-2);
  width: auto;
`;

export const ButtonWrapper = styled.div<{
  isStretched?: boolean;
  isLast?: boolean;
}>`
  display: flex;
  ${({ isStretched }) =>
    isStretched &&
    css`
      flex-grow: 1;
    `}
  ${({ isLast }) =>
    isLast &&
    css`
      margin-left: var(--fd-space-space-2);
    `}
`;

export const ButtonText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RunnersContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
