import React from "react";
import useQuickPicksQuery from "../../hooks/useQuickPicksQuery";
import { QuickPicksDataType } from "../../types";
import LoadingContainer from "../LoadingContainer";
import RaceCardContainer from "../RaceCardContainer";

const QuickPicksModule = ({
  qaLabel = "quickPicks",
  title
}: {
  qaLabel?: string;
  title: string;
}) => {
  const { quickPicksData, loading: loadingQuickPickRaces } = useQuickPicksQuery(
    { tvgRaceIds: [] }
  );

  if (loadingQuickPickRaces) {
    return <LoadingContainer />;
  }

  const tvgRaceIdsFromQuickPicks = quickPicksData?.map(
    (qp: QuickPicksDataType) => qp.tvgRaceId
  );

  if (!tvgRaceIdsFromQuickPicks || !tvgRaceIdsFromQuickPicks.length) {
    return null;
  }

  return (
    <RaceCardContainer
      raceIds={tvgRaceIdsFromQuickPicks}
      quickPicksData={quickPicksData}
      qaLabel={qaLabel}
      title={title}
    />
  );
};

export default QuickPicksModule;
