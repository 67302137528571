import styled, { css } from "styled-components";

export const Container = styled.div<{ isScratched?: boolean }>`
  max-width: 90px;
  ${({ isScratched }) =>
    isScratched &&
    css`
      opacity: 40%;
    `}
`;
