import { gql } from "@apollo/client";

export const RunnerHandicappingFragment = {
  entry: gql`
    fragment handicappingFragment on Runner {
      handicapping {
        freePick {
          number
        }
      }
    }
  `
};
