import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setHPTemplateSelectorVisibility } from "@tvg/pref/src/components/HomepageConfig/utils";
import { Banner } from "@urp/banners/src";
import { BannersConfigContext } from "@urp/banners/src/types";
import { PreferencesTabEnum } from "@tvg/sh-lib-preferences/utils/types";
import { getShowHPTemplateSelector } from "@tvg/sh-lib-preferences/redux/selectors";
import { getAccountNumber, getIsLogged } from "@urp/store-selectors";
import { getHomepageTemplates } from "../../store/selectors";
import HPTemplateSelector from "../HPTemplateSelector";
import { VIEW_TYPE_SELECTOR } from "../HPTemplateSelector/types";
import { renderComponents } from "../../utils";
import { SectionConfigType } from "../../types";
import {
  TopSection,
  SectionsWrapper,
  Main,
  Bottom,
  Side
} from "./styled-components";

const DesktopHomepage = ({
  selectedTemplate,
  isHomepageConfigurable
}: {
  selectedTemplate: string;
  isHomepageConfigurable: boolean;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(getIsLogged);
  const accountNumber = useSelector(getAccountNumber);
  const templates = useSelector(getHomepageTemplates);
  const showHPTemplateSelector = useSelector(getShowHPTemplateSelector);

  return (
    <>
      <TopSection data-qa-label="top-section">
        <Banner context={BannersConfigContext.HOME_PAGE} />
        {showHPTemplateSelector && isHomepageConfigurable && isLoggedIn && (
          <HPTemplateSelector
            viewTypeSelector={VIEW_TYPE_SELECTOR.BANNER}
            onSettingsClick={() =>
              navigate("/preferences", {
                state: { selectedTab: PreferencesTabEnum.HOMEPAGE_CONFIG }
              })
            }
            onDismiss={() =>
              setHPTemplateSelectorVisibility(accountNumber, false, dispatch)
            }
          />
        )}
        {renderComponents(templates, SectionConfigType.TOP, selectedTemplate)}
      </TopSection>
      <SectionsWrapper>
        <Main data-qa-label="main-section">
          {renderComponents(
            templates,
            SectionConfigType.MAIN,
            selectedTemplate
          )}
          <Bottom data-qa-label="bottom-section">
            {renderComponents(
              templates,
              SectionConfigType.BOTTOM,
              selectedTemplate
            )}
          </Bottom>
        </Main>
        <Side data-qa-label="side-section">
          {renderComponents(
            templates,
            SectionConfigType.SIDE,
            selectedTemplate
          )}
        </Side>
      </SectionsWrapper>
    </>
  );
};

export default DesktopHomepage;
