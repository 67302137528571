import styled, { css } from "styled-components";
import { breakpoints } from "@tvg/design-system";

export const Container = styled.div<{ isScratched?: boolean }>`
  display: grid;
  grid-template-columns: ${({ isScratched }) =>
    isScratched ? "60px 180px 81px " : "60px 190px 60px"};
  gap: var(--fd-space-space-2);
  height: 82px;
  width: 100%;
  padding: var(--fd-space-space-2) var(--fd-space-space-3)
    var(--fd-space-space-2) 0;
  @media screen and ${breakpoints.tablet.max.sm} {
    grid-template-columns: ${({ isScratched }) =>
      isScratched ? "40px 193px 81px " : "40px 214px 60px"};
  }
`;

export const TextContainer = styled.div<{
  isScratched?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: var(--fd-space-space-05);
  height: 64px;

  ${({ isScratched }) =>
    isScratched
      ? css`
          color: var(--fd-colors-content-disabled);
        `
      : css`
          color: var(--fd-colors-content-default);
        `}
`;
export const HeaderContainer = styled.div`
  display: flex;
  gap: var(--fd-space-space-1);
`;

export const RunnerName = styled.div`
  font-size: var(--fd-font-sizes-body-medium-strong);
  font-family: "Roboto-Medium", sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const multiLineTextEllipsis = (lines = 2) => ({
  maxWidth: "100%",
  display: "-webkit-box",
  "-webkit-line-clamp": lines.toString(),
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis"
});

export const Description = styled.div<{ isScratched?: boolean }>`
  font-weight: var(--fd-font-weights-body-small-regular);
  font-size: var(--fd-font-sizes-body-small-regular);
  line-height: 15px;

  ${multiLineTextEllipsis(3)};
  ${({ isScratched }) =>
    isScratched &&
    css`
      text-decoration: var(
        --fd-text-decoration-body-small-regular-strikethrough
      );
    `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: calc(-1 * var(--fd-space-space-1));
`;
