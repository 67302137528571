import React from "react";
import Homepage from "@urp/homepage/src/index";
import { HomeContainer } from "./styled-components";

export const Home = () => (
  <HomeContainer>
    <Homepage />
  </HomeContainer>
);

export default Home;
