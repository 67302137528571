import styled from "styled-components";
import { compose, space } from "styled-system";

const saddleLabelContainerStyles = compose(space);

export const SaddleLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${saddleLabelContainerStyles}
`;
