import { useEffect } from "react";
import { attempt } from "lodash";
import { SubscribeToMoreOptions } from "@apollo/client/core";
import tvgConf from "@tvg/conf";
import { GET_RACES_SUBSCRIPTION } from "../graphql/queries/RacesSubscription";

interface UseRacesSubscriptionParams {
  tvgRaceIds?: number[];
  subscribeToMore: (options: SubscribeToMoreOptions) => () => void;
}

const useRacesSubscription = ({
  tvgRaceIds,
  subscribeToMore
}: UseRacesSubscriptionParams) => {
  useEffect(() => {
    let unSubscribe: unknown = null;
    if (tvgRaceIds?.length) {
      attempt(() => {
        unSubscribe = subscribeToMore({
          document: GET_RACES_SUBSCRIPTION,
          variables: {
            tvgRaceIds,
            ...tvgConf().graphContext()
          }
        });
      });
    }

    return () => {
      if (typeof unSubscribe === "function") {
        unSubscribe();
      }
    };
  }, [JSON.stringify(tvgRaceIds), subscribeToMore]);
};

export default useRacesSubscription;
