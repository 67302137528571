import {
  SuggestedRunnersOrder,
  PoolThresholdAmount,
  MinimumWagerAmount,
  UpcomingRacesMaxResults,
  SrpConfig
} from "./types";

export const defaultSuggestedRunnersOrder: SuggestedRunnersOrder = [
  "handicapping",
  "timeform"
];

export const defaultPoolThresholdAmount: PoolThresholdAmount = 0;

export const defaultMinimumWagerAmount: MinimumWagerAmount = 1;

export const defaultUpcomingRacesMaxResults: UpcomingRacesMaxResults = 5;

export const defaultSrpConfig: SrpConfig = {
  minimumWagerAmount: defaultMinimumWagerAmount,
  thirdPartyPicksSuggestionOrder: defaultSuggestedRunnersOrder,
  poolThresholdAmount: defaultPoolThresholdAmount,
  upcomingRacesMaxResults: defaultUpcomingRacesMaxResults
};
