import React from "react";
import { LoadingBlock } from "../Loading";
import { SVG } from "../Loading/styled-components";
import {
  Container,
  ScrollableContainer,
  Element,
  AboveHeaderBlock
} from "./styled-components";
import { LoadingProps } from "../types";

export const LoadingContainer = ({ isDarkMode }: LoadingProps) => (
  <Container
    data-qa-label={
      isDarkMode ? "darkMode-loadingContainer" : "lightMode-loadingContainer"
    }
    isDarkMode={isDarkMode}
  >
    <AboveHeaderBlock>
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        width="150"
        height="16"
        viewBox="0 0 150 16"
        fill="none"
      >
        <rect
          className={isDarkMode ? "darkPulse" : "lightPulse"}
          width="150"
          height="16"
          rx="1"
          fill="white"
          fillOpacity={isDarkMode ? "0.05" : "none"}
        />
      </SVG>
    </AboveHeaderBlock>
    <ScrollableContainer>
      {[...Array(2)].map((_, index) => (
        <Element key={`card-empty-${index.toString()}`}>
          <LoadingBlock isDarkMode={isDarkMode} />
        </Element>
      ))}
    </ScrollableContainer>
  </Container>
);

export default LoadingContainer;
