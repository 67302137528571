import { useEffect } from "react";
import { attempt } from "lodash";
import { SubscribeToMoreOptions } from "@apollo/client/core";
import tvgConf from "@tvg/conf";
import { GET_SRP_RACES_SUBSCRIPTION } from "../graphql/queries/SrpRacesSubscription";

interface UseSrpRacesSubscriptionParams {
  wagerProfile: string | undefined;
  tvgRaceIds: (number | undefined)[];
  subscribeToMore: (options: SubscribeToMoreOptions) => () => void;
}

const useSrpRacesSubscription = ({
  wagerProfile,
  tvgRaceIds,
  subscribeToMore
}: UseSrpRacesSubscriptionParams) => {
  useEffect(() => {
    let unSubscribe: unknown = null;
    if (tvgRaceIds.length) {
      attempt(() => {
        unSubscribe = subscribeToMore({
          document: GET_SRP_RACES_SUBSCRIPTION,
          variables: {
            wagerProfile,
            tvgRaceIds,
            ...tvgConf().graphContext()
          }
        });
      });
    }

    return () => {
      if (typeof unSubscribe === "function") {
        unSubscribe();
      }
    };
  }, [JSON.stringify(tvgRaceIds), wagerProfile, subscribeToMore]);
};

export default useSrpRacesSubscription;
