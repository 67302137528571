import styled from "styled-components";

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--fd-space-space-3);
`;

export const StandaloneWrapper = styled.span`
  width: 100%;
`;

export const TemplateSelectorContainer = styled.div`
  background-color: var(--fd-colors-core-navy-d-3);
`;

export const TemplateSelectorBackground = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
`;
