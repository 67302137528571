import React from "react";
import { Tag } from "@tvg/design-system/web";
import { TagContainerProps } from "./types";
import { Container } from "./styled-components";

const TagContainer = ({ label, isScratched, variant }: TagContainerProps) => {
  const tagType = isScratched ? "scratched-tag" : "tag";

  return (
    <Container isScratched={isScratched}>
      <Tag
        qaLabel={tagType}
        label={label}
        variant={variant}
        size="small"
        isEllipsed
        hideIcon
      />
    </Container>
  );
};
export default TagContainer;
