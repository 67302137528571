import { get } from "lodash";
import { BettingInterest, Runner } from "@tvg/ts-types/Race";
import { defaultSuggestedRunnersOrder } from "../../redux/defaultValues";

export const isScratched = (runner: Runner): boolean => runner?.scratched;

export const filterByDuplicateRunners = (runners: Runner[]): Runner[] =>
  runners?.filter(
    (runner, index, self) =>
      self.findIndex(
        (otherRunner) => otherRunner.runnerId === runner.runnerId
      ) === index
  ) ?? [];

// Return a list of all runners from a list of BettingInterests
export const getAllRunnersFromBettingInterests = (
  bettingInterests: BettingInterest[]
): Runner[] =>
  bettingInterests?.reduce(
    (allRunners: Runner[], bettingInterest: BettingInterest) => {
      bettingInterest?.runners?.forEach((runner: Runner) =>
        allRunners.push(runner)
      );
      return allRunners;
    },
    []
  ) ?? [];

/**
 * Returns a list of runners that are not scratched and have a freePick number
 * of the specified thirdPartyType
 */
export const filterRunnersByAvailableThirdPartyType = (
  thirdPartyType: string,
  runners: Runner[]
) =>
  runners?.filter(
    (runner: Runner) =>
      !isScratched(runner) && get(runner, `${thirdPartyType}.freePick.number`)
  ) ?? [];

// Returns runners sorted in ascending order by third party freePick number
export const sortRunnersByThirdPartyType = (
  thirdPartyType: string,
  runners: Runner[]
) =>
  runners?.slice().sort((runner1: Runner, runner2: Runner) => {
    const freePick1 = get(runner1, `${thirdPartyType}.freePick.number`);
    const freePick2 = get(runner2, `${thirdPartyType}.freePick.number`);
    return freePick1 - freePick2;
  }) ?? [];

/**
 * Returns a list of the top specified number of runners by a third party pick type,
 * in the order the third party picks are specificied, otherwise an empty list
 */
export const getSuggestedRunnersByOrder = (
  runners: Runner[],
  thirdPartyPicksSuggestionOrder: string[] = defaultSuggestedRunnersOrder,
  numberOfSuggestedRunners: number = 2 // 2 is default for Exacta Box SRP
): Runner[] => {
  let suggestedRunners: Runner[] = [];

  thirdPartyPicksSuggestionOrder.find((thirdParty: string) => {
    const potentialSuggestedRunners = filterRunnersByAvailableThirdPartyType(
      thirdParty,
      runners
    );
    if (potentialSuggestedRunners.length < numberOfSuggestedRunners) {
      return false;
    }

    suggestedRunners = sortRunnersByThirdPartyType(
      thirdParty,
      potentialSuggestedRunners
    );
    return true;
  });

  const uniqueSuggestedRunners = filterByDuplicateRunners(suggestedRunners);

  return uniqueSuggestedRunners.slice(0, numberOfSuggestedRunners);
};

/**
 * Creates a list of all orders that the top 2 runners can finish in
 *
 * For example, if runners = [{ runnerId: "1"}, { runnerId: "2"}]
 * Creates a list of:
 * [[1, 2],
 *  [2, 1]]
 *
 * Returns one list in string value and one in number value
 */
export const prepareRunnerSelection = (
  runners: Runner[]
): { asStrings: string[][]; asNumbers: number[][] } => {
  const asStrings: string[][] = [];
  const asNumbers: number[][] = [];

  runners?.forEach((runner1, runner1Index) => {
    runners.forEach((runner2, runner2Index) => {
      if (runner1Index !== runner2Index) {
        asStrings.push([runner1?.runnerId, runner2?.runnerId]);
        asNumbers.push([+runner1?.runnerId, +runner2?.runnerId]);
      }
    });
  });

  return { asStrings, asNumbers };
};
