import mediator from "@tvg/mediator";
import { QuickPicksCTAEventProps } from "@urp/amplitude/src/modules/quickPicks/types";

export const quickPickCardCTAEvent = ({
  raceNumber,
  trackName,
  position,
  linkUrl,
  linkText
}: QuickPicksCTAEventProps) => {
  mediator.base.dispatch({
    type: "QP:QUICK_PICKS_CARD_CTA_CLICKED",
    payload: {
      raceNumber,
      trackName,
      position,
      linkUrl,
      linkText
    }
  });
};
