import { gql } from "@apollo/client";
import { RunnerHandicappingFragment } from "../fragments/RunnerHandicappingFragment";
import { RunnerTimeformFragment } from "../fragments/RunnerTimeformFragment";

export const GET_SRP_RACES_QUERY = gql`
  query getSrpRaces($tvgRaceIds: [Long], $wagerProfile: String) {
    races: races(tvgRaceIds: $tvgRaceIds, profile: $wagerProfile) {
      id
      tvgRaceId
      number
      trackCode
      trackName
      postTime
      mtp
      status {
        code
      }
      wagerTypes(filters: [{ code: "EXB" }]) {
        code
        minWagerAmount
        maxWagerAmount
        isBox
        type {
          id
          code
          name
        }
      }
      probables(filter: { wagerCode: "EX" }) {
        amount
        minWagerAmount
        wagerType {
          id
          code
          name
        }
        betCombos {
          runner1
          runner2
          payout
        }
      }
      bettingInterests {
        favorite
        runners {
          runnerId
          entityRunnerId
          scratched
          horseName
          ...timeformFragment
          ...handicappingFragment
          __typename
        }
        __typename
      }
      __typename
    }
  }
  ${RunnerHandicappingFragment.entry}
  ${RunnerTimeformFragment.entry}
`;
