import React from "react";
import type { SpaceProps } from "styled-system";
import { Paragraph, Saddle, SaddleProps } from "@tvg/design-system/web";
import { SaddleLabelContainer } from "./styled-components";

export interface SaddleLabelProps extends SaddleProps, SpaceProps {
  horseName: string;
}

const SaddleLabel = ({
  number,
  size,
  horseName,
  ...rest
}: SaddleLabelProps) => (
  <SaddleLabelContainer {...rest}>
    <Saddle number={number} size={size} />
    <Paragraph ml="var(--fd-space-space-2)">{horseName}</Paragraph>
  </SaddleLabelContainer>
);

export default SaddleLabel;
