import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { GET_RACES_QUERY } from "../graphql/queries/RacesQuery";
import { apolloOptionsRaces } from "../graphql/options.graph";
import useRacesSubscription from "./useRacesSubscription";

interface UseQuickPicksQueryParams {
  tvgRaceIds?: number[];
}

const useRacesQuery = ({ tvgRaceIds }: UseQuickPicksQueryParams) => {
  const apolloOptions = useMemo(
    () => apolloOptionsRaces({ tvgRaceIds }),
    tvgRaceIds
  );

  const { data, loading, subscribeToMore } = useQuery(
    GET_RACES_QUERY,
    apolloOptions
  );

  useRacesSubscription({ tvgRaceIds, subscribeToMore });

  return useMemo(
    () => ({
      loading,
      allRacesByTvgRaceIds: data?.races
    }),
    [loading, data]
  );
};
export default useRacesQuery;
