import { gql } from "@apollo/client";

export const RunnerTimeformFragment = {
  entry: gql`
    fragment timeformFragment on Runner {
      timeform {
        freePick {
          number
        }
      }
    }
  `
};
