import { isEmpty, flatten, get, size } from "lodash";
import type {
  Probable,
  RaceWagerType,
  ProbableBetCombo
} from "@tvg/ts-types/Race";
import formatCurrency from "@tvg/formatter/currency";
import { WagerTypes, WagerTypesPos } from "@tvg/ts-types/Wager";

import type { WagerType } from "@tvg/ts-types/Wager";
import type { BetComboHash } from "./generalTypes";
import { exactaAndDailyDouble } from "./potentialReturn/exactaAndDailyDouble";
import { quinella } from "./potentialReturn/quinella";
import { placeAndShow } from "./potentialReturn/placeAndShow";
import { win } from "./potentialReturn/win";

export const PROBABLES_DELIMITTER = " - ";

export const parseProbablesString = (probables: string): string[] =>
  typeof probables === "string" ? probables.split(PROBABLES_DELIMITTER) : [];

export const doesRaceWagerTypeHasProbables = (
  probables: Probable[],
  currentWagerTypeId: number
): boolean => {
  if (!isEmpty(probables) && currentWagerTypeId) {
    let probableIndex = -1;
    // Checking if has index there for it means we have a probable for current wager type
    if (
      currentWagerTypeId === WagerTypes.WIN_PLACE_SHOW ||
      currentWagerTypeId === WagerTypes.WIN_PLACE ||
      currentWagerTypeId === WagerTypes.WIN_SHOW
    ) {
      [WagerTypes.WIN, WagerTypes.PLACE, WagerTypes.SHOW].forEach((type) => {
        const possibleIndex = probables.findIndex(
          (probable: Probable) => probable.wagerType.id === type
        );
        if (possibleIndex > -1) {
          probableIndex = possibleIndex;
        }
      });
    } else {
      probableIndex = probables.findIndex(
        (probable: Probable) => probable.wagerType.id === currentWagerTypeId
      );
    }

    if (probableIndex > -1) {
      return true;
    }
  }

  return false;
};

export const calculateProbableValue = (
  comboAmount: number,
  minWagerAmount: number,
  userAmount: number
): number => (comboAmount * userAmount) / minWagerAmount;

export const formatProbableAmountList = (
  probableAmountList: number[],
  betAmount: number
): string => {
  const probableAmountLength = probableAmountList.length;

  if (!probableAmountLength) {
    return "0";
  }

  if (probableAmountLength === 1) {
    return formatCurrency(probableAmountList[0]);
  }

  // To ensure that minimum probable value is always more than my initial bet amount
  const probableAmountDefault = betAmount * 1.05;
  const minValue = formatCurrency(
    Math.max(Math.min(...probableAmountList), probableAmountDefault)
  );
  const maxValue = formatCurrency(
    Math.max(...probableAmountList, probableAmountDefault)
  );

  if (minValue === maxValue) {
    return minValue;
  }

  if (minValue === "$0.00" && maxValue !== "$0.00") {
    return maxValue;
  }

  return `${minValue}${PROBABLES_DELIMITTER}${maxValue}`;
};

export const getBetCombosHash = (betCombos: ProbableBetCombo[]): BetComboHash =>
  betCombos.reduce(
    (accumulator: BetComboHash, currentValue: ProbableBetCombo) => {
      const newValue: BetComboHash = {};

      if (currentValue.runner2 === null) {
        newValue[currentValue.runner1] = currentValue.payout;
      } else {
        newValue[`${currentValue.runner1}-${currentValue.runner2}`] =
          currentValue.payout;
      }

      return {
        ...accumulator,
        ...newValue
      };
    },
    {}
  );

export const checkKindOfSpecialGroupType = (
  betSelections: string[][]
): number | number[] => {
  let type;

  const winSelections = get(betSelections, `[${WagerTypesPos.Win}].length`, 0);
  const placeSelections = get(
    betSelections,
    `[${WagerTypesPos.Place}].length`,
    0
  );
  const showSelections = get(
    betSelections,
    `[${WagerTypesPos.Show}].length`,
    0
  );

  // Check if single wager type
  if (winSelections > 0 && placeSelections === 0 && showSelections === 0) {
    type = WagerTypes.WIN;
  } else if (
    placeSelections > 0 &&
    winSelections === 0 &&
    showSelections === 0
  ) {
    type = WagerTypes.PLACE;
  } else if (
    showSelections > 0 &&
    winSelections === 0 &&
    placeSelections === 0
  ) {
    type = WagerTypes.SHOW;
  } else {
    type = WagerTypes.WIN_PLACE_SHOW;
  }

  // Check if is multiple wager types and max runners is 3
  if (type === WagerTypes.WIN_PLACE_SHOW) {
    const includedRunners: Array<string> = [];
    betSelections.forEach((selection) => {
      selection.forEach((runner) => {
        if (!includedRunners.includes(runner)) {
          includedRunners.push(runner);
        }
      });
    });

    if (includedRunners.length <= 3) {
      return betSelections.reduce(
        (acc: Array<number>, selection: Array<string>, index: number) => {
          if (selection.length > 0) {
            switch (index) {
              case WagerTypesPos.Win:
                return [...acc, WagerTypes.WIN];
              case WagerTypesPos.Place:
                return [...acc, WagerTypes.PLACE];
              case WagerTypesPos.Show:
                return [...acc, WagerTypes.SHOW];
              default:
                return [];
            }
          }
          return acc;
        },
        []
      ); // case of wagers in a win and place this will return [10, 20]
    }
  }

  return type;
};

export const getProbableValues = (
  probableIndex: number,
  probables: Probable[],
  betSelections: string[][],
  betAmount: number
): number[] => {
  if (probableIndex !== -1) {
    // Search and calculate probable
    const { wagerType, minWagerAmount, betCombos } = probables[probableIndex];

    // Turn betCombo into an hashTable
    const betCombosHash = getBetCombosHash(betCombos);

    // Find which payout we want to multiply

    const typesProps = {
      betSelections,
      betCombosHash,
      minWagerAmount,
      betAmount
    };

    switch (wagerType.id) {
      // Exacta
      case WagerTypes.EXACTA:
        return exactaAndDailyDouble(typesProps);
      // Daily Double
      case WagerTypes.DAILY_DOUBLE:
        return exactaAndDailyDouble(typesProps);
      // Quinella
      case WagerTypes.QUINELLA:
        return quinella(typesProps);
      // Show
      case WagerTypes.SHOW:
        return placeAndShow({
          ...typesProps,
          wagerTypeId: wagerType.id
        });
      // Place
      case WagerTypes.PLACE:
        return placeAndShow({
          ...typesProps,
          wagerTypeId: wagerType.id
        });
      // Win
      case WagerTypes.WIN:
        return win(typesProps);
      default:
        return [];
    }
  }

  return [];
};

export const formatSelectionsArray = (
  wagerTypeId: number,
  betSelections: string[][]
): string[][] => {
  if (wagerTypeId === WagerTypes.WIN) {
    return [get(betSelections, `[${WagerTypesPos.Win}]`, [])];
  }
  if (wagerTypeId === WagerTypes.PLACE) {
    return [get(betSelections, `[${WagerTypesPos.Place}]`, [])];
  }
  if (wagerTypeId === WagerTypes.SHOW) {
    return [get(betSelections, `[${WagerTypesPos.Show}]`, [])];
  }

  return [];
};

export const getCurrentProbableValue = (
  probables: Probable[],
  currentWagerType: RaceWagerType | WagerType,
  betAmount: number,
  betSelections: string[][],
  isSpecialGroup: boolean = false
): string | undefined => {
  let probableValueList: number[] = [];
  let betSelectionsOrganized = betSelections;

  if (
    !isEmpty(probables) &&
    !isEmpty(currentWagerType) &&
    betAmount &&
    !isEmpty(flatten(betSelectionsOrganized))
  ) {
    let probableIndex = -1;
    let specialGroupTypeId: number | Array<number> | null = isSpecialGroup
      ? -1
      : null;

    // is the currentWagerType selected in the probables array
    if (isSpecialGroup) {
      specialGroupTypeId = checkKindOfSpecialGroupType(betSelectionsOrganized);
    } else {
      probableIndex = probables.findIndex(
        (probable: Probable) =>
          probable.wagerType.id === get(currentWagerType, "type.id") ||
          probable.wagerType.id === get(currentWagerType, "id")
      );
    }

    if (specialGroupTypeId && typeof specialGroupTypeId === "number") {
      probableIndex = probables.findIndex(
        (probable: Probable) => probable.wagerType.id === specialGroupTypeId
      );

      betSelectionsOrganized = formatSelectionsArray(
        specialGroupTypeId,
        betSelections
      );
    }

    // If is combined wager we have to get all amounts and sum

    if (
      specialGroupTypeId &&
      Array.isArray(specialGroupTypeId) &&
      specialGroupTypeId.length
    ) {
      const allProbables = specialGroupTypeId.map((wagerTypeId) => {
        const selections = formatSelectionsArray(wagerTypeId, betSelections);

        const probIndex = probables.findIndex(
          (probable: Probable) => probable.wagerType.id === wagerTypeId
        );

        return getProbableValues(probIndex, probables, selections, betAmount);
      });

      // Check if has invalid probable
      if (allProbables.find((probable) => !size(probable))) {
        return undefined;
      }

      let minOfMin = 999999;
      let maxOfWin = 0;
      let maxOfPlace = 0;
      let maxOfShow = 0;

      allProbables.forEach((probable, index) => {
        // Get biggest numbers for each wager
        if (index === WagerTypesPos.Win) {
          maxOfWin = Math.max(...probable);
        } else if (index === WagerTypesPos.Place) {
          maxOfPlace = Math.max(...probable);
        } else if (index === WagerTypesPos.Show) {
          maxOfShow = Math.max(...probable);
        }

        // Get lowest number but checking on each wager
        const currentMinNumber = Math.min(...probable);
        if (currentMinNumber < minOfMin) {
          minOfMin = currentMinNumber;
        }
      });

      const sumOfWagerTypes = maxOfWin + maxOfPlace + maxOfShow;
      probableValueList = [minOfMin, sumOfWagerTypes];
    } else {
      probableValueList = getProbableValues(
        probableIndex,
        probables,
        betSelectionsOrganized,
        betAmount
      );
    }

    return formatProbableAmountList(probableValueList, betAmount);
  }

  return "0";
};
