import React, { useCallback, useEffect, useRef, useState } from "react";
import ComponentSectionHeaderMask from "@fdr/static-ui/Masks/ComponentSectionHeaderMask";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import {
  SectionWrapper,
  SectionHeader,
  SectionContent
} from "./styled-components";
import HeaderComponent from "../HeaderComponent";
import { ComponentSectionProps } from "./types";

const ComponentSection = ({
  componentName,
  title,
  subtitle,
  hasHeaderSpace,
  children,
  isLoading,
  isNightMode,
  hasBackground,
  hasTopPadding,
  seeAllLink,
  qaLabel = "generic-section",
  onSectionVisibilityChanges
}: ComponentSectionProps) => {
  const ref = useRef<HTMLElement>(null);
  const sectionWrapperRef = useRef<HTMLElement>(null);
  const [isVisible, setIsVisible] = useState(true);
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);

  const checkAndSetVisibility = useCallback(() => {
    const hasContent = !!ref.current?.clientHeight;
    setIsVisible(hasContent);
    onSectionVisibilityChanges?.(
      componentName,
      title,
      sectionWrapperRef,
      hasContent
    );
  }, [componentName, title, onSectionVisibilityChanges]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      checkAndSetVisibility();
    }
  }, [checkAndSetVisibility]);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList") {
          checkAndSetVisibility();
        }
      });
    });

    if (ref.current) {
      observer.observe(ref.current, { childList: true });
    }

    return () => {
      observer.disconnect();
    };
  }, [checkAndSetVisibility]);

  const hasHeader = !!(title || subtitle);

  return children ? (
    <SectionWrapper
      isNightMode={isNightMode}
      hasBackground={hasBackground}
      data-qa-label={qaLabel}
      isVisible={isVisible}
      className={
        isVisible ? "section-wrapper-visible" : "section-wrapper-hidden"
      }
      ref={sectionWrapperRef}
    >
      {hasHeader && isMobile && (
        <SectionHeader data-qa-label={`${qaLabel}-header`}>
          {isLoading ? (
            <ComponentSectionHeaderMask />
          ) : (
            <HeaderComponent
              title={title}
              subtitle={subtitle}
              seeAllLink={seeAllLink}
            />
          )}
        </SectionHeader>
      )}
      <SectionContent
        ref={ref}
        hasHeader={hasHeader || !hasHeaderSpace}
        hasTopPadding={!!hasTopPadding}
        data-qa-label={`${qaLabel}-content`}
      >
        {children}
      </SectionContent>
    </SectionWrapper>
  ) : null;
};

export default ComponentSection;
