import styled from "styled-components";

export const OddsContainer = styled.div`
  font-weight: 700;
  font-size: var(--fd-font-sizes-button-large-strong);
`;

export const MessageContainer = styled.div`
  font-weight: 400;
  font-size: var(--fd-font-sizes-button-small-regular);
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--fd-space-space-05);
  padding-top: var(--fd-space-space-05);
`;
