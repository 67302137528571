import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@tvg/design-system/web";
import {
  closePreferencesModalAction,
  openPreferencesModalAction
} from "@tvg/sh-lib-preferences/redux/actions";
import {
  getAlertPrefs,
  getIsPreferencesModalOpen,
  getSelectedTab
} from "@tvg/sh-lib-preferences/redux/selectors";
import { useLocation } from "react-router-dom";
import { Container } from "./styled-components";
import PreferencesCenter from "../PreferencesCenter";
import useTabs from "../../hooks/useTabs";
import useTabSelection from "../../hooks/useTabSelection";
import ConfirmModal from "../ConfirmModal";

const PreferencesModal = () => {
  const dispatch = useDispatch();
  const alertState = useSelector(getAlertPrefs);
  const isOpen = useSelector(getIsPreferencesModalOpen);
  const selectedTab = useSelector(getSelectedTab);
  const { tabs, defaultTab, hasNotificationTab } = useTabs();
  const onSelectTab = useTabSelection();
  const { state } = useLocation();

  useEffect(() => {
    if (state?.preferences?.selectedTab) {
      dispatch(openPreferencesModalAction());
    }
  }, []);

  return (
    <>
      <Modal
        isOpen={isOpen}
        headerProps={{
          title: "Preferences",
          onBack: () => dispatch(closePreferencesModalAction()),
          showBackButton: true
        }}
        onClose={() => dispatch(closePreferencesModalAction())}
        qaLabel="preferences-modal"
        hasMaxHeight={true}
        padding={0}
      >
        <Container>
          <PreferencesCenter
            onSelect={(tab) => onSelectTab(tab, selectedTab)}
            selectedTab={
              state?.preferences?.selectedTab || selectedTab || defaultTab
            }
            tabs={tabs}
            hasAlert={alertState.show}
            hasNotificationTab={hasNotificationTab}
            isMobileLayout
          />
        </Container>
      </Modal>
      <ConfirmModal />
    </>
  );
};

export default PreferencesModal;
