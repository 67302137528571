import { gql } from "@apollo/client";

export const GET_QUICK_PICKS_QUERY = gql`
  query quickPicks($tvgRaceIds: [Int!]) {
    quickPicks(tvgRaceIds: $tvgRaceIds) {
      tvgRaceId
      headerLogoUrl
      raceDate
      quickPicks {
        pickId
        tag
        summary
        wagerTypeAbbreviation
        selections
      }
    }
  }
`;
