import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { getWagerProfile } from "@urp/store-selectors";
import { GET_SRP_RACES_QUERY } from "../graphql/queries/SrpRacesQuery";
import { apolloOptionsSrpRaces } from "../graphql/options.graph";
import useSrpRacesSubscription from "./useSrpRacesSubscription";

interface UseSrpRacesParams {
  tvgRaceIds: (number | undefined)[];
}

const useSrpRaces = ({ tvgRaceIds }: UseSrpRacesParams) => {
  const wagerProfile = useSelector(getWagerProfile);
  const apolloOptions = useMemo(
    () => apolloOptionsSrpRaces({ wagerProfile, tvgRaceIds }),
    [wagerProfile, tvgRaceIds]
  );

  const { loading, subscribeToMore, data } = useQuery(
    GET_SRP_RACES_QUERY,
    apolloOptions
  );

  useSrpRacesSubscription({ wagerProfile, tvgRaceIds, subscribeToMore });

  return useMemo(
    () => ({
      loading,
      srpRaces: data?.races
    }),
    [loading, data]
  );
};

export default useSrpRaces;
