import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getIsLogged } from "@urp/store-selectors";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import { useAllowChoseBuiltInProfile } from "@urp/amplitude-experiments";
import {
  getHomepageTemplateUserPrefDesktop,
  getHomepageTemplateUserPrefMobile,
  getHomepageConfigurableToggle
} from "@tvg/sh-lib-preferences/redux/selectors";
import { isFdrProduct } from "@tvg/sh-utils/mobileUtils";
import tvgConf from "@tvg/conf";
import { HomeContainer } from "./styled-components";
import DesktopHomepage from "./components/DesktopHomepage";
import MobileHomepage from "./components/MobileHomepage";

const Homepage = (): JSX.Element => {
  const isLoggedIn = useSelector(getIsLogged);
  const isHomepageConfigurable = useSelector(getHomepageConfigurableToggle);

  const [selectedTemplate, setSelectedTemplate] = useState<string>("default");
  const allowChoseProfile = useAllowChoseBuiltInProfile();

  const isMobileDevice = tvgConf().device === "mobile";
  const isTabletSize = useMediaQuery(breakpoints.tablet.max.sm);
  const isMobile = isMobileDevice || isTabletSize || isFdrProduct();

  const userPrefTemplate = useSelector(
    isMobile
      ? getHomepageTemplateUserPrefMobile
      : getHomepageTemplateUserPrefDesktop
  );

  useEffect(() => {
    if (!isLoggedIn || !userPrefTemplate || !allowChoseProfile) {
      setSelectedTemplate("default");
    } else {
      setSelectedTemplate((userPrefTemplate || "default").toLowerCase());
    }
  }, [isLoggedIn, userPrefTemplate, allowChoseProfile]);

  const isConfigurable = isHomepageConfigurable || allowChoseProfile;

  return (
    <HomeContainer data-qa-label="home-container">
      {isMobile ? (
        <MobileHomepage
          selectedTemplate={selectedTemplate}
          isHomepageConfigurable={isConfigurable}
        />
      ) : (
        <DesktopHomepage
          selectedTemplate={selectedTemplate}
          isHomepageConfigurable={isConfigurable}
        />
      )}
    </HomeContainer>
  );
};

export default Homepage;
