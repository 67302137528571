import buildRaceUrl, { addParamToURL } from "@tvg/formatter/url";
import { formatNumber } from "@tvg/formatter/currency";
import { SRPWagerBuilderParams } from "../../components/types";
import { prepareRunnerSelection } from "../runners";

export const buildSameRaceParlayWagerUrl = ({
  trackAbbr,
  trackName,
  raceNumber,
  bet,
  wt,
  suggestedRunners,
  showConfirmation
}: SRPWagerBuilderParams): string => {
  let wagerUrl: string = buildRaceUrl(trackAbbr, trackName, raceNumber);

  if (bet) {
    wagerUrl = addParamToURL(wagerUrl, "bet", formatNumber(bet));
  }

  if (wt) {
    wagerUrl = addParamToURL(wagerUrl, "wt", wt);
  }

  if (suggestedRunners) {
    const { asStrings: selections } = prepareRunnerSelection(suggestedRunners);
    const selectionsParams = selections.map((selection) => selection.join(","));
    selectionsParams.forEach((selection, index) => {
      wagerUrl = addParamToURL(wagerUrl, `s${index}`, selection);
    });
  }

  if (showConfirmation) {
    wagerUrl = addParamToURL(wagerUrl, "showConfirmation", "true");
  }

  return wagerUrl;
};
