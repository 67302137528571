import { gql } from "@apollo/client";

export const RACE = gql`
  fragment race on Race {
    tvgRaceId
    highlighted {
      description
    }
    id
    isGreyhound
    track {
      code
      name
      location {
        country
        state
      }
    }
    type {
      code
    }
    status {
      code
    }
    trackName
    number
    trackCode
    postTime
    promos {
      isPromoTagShown
    }
    mtp
    video {
      liveStreaming
      onTvg
      onTvg2
    }
    bettingInterests {
      biNumber
      currentOdds {
        numerator
        denominator
      }
      saddleColor
      numberColor
      runners {
        runnerId
        horseName
        entityRunnerId
        scratched
        timeform {
          silkUrlSvg
        }
      }
    }
  }
`;
