import { QueryHookOptions } from "@apollo/client";
import tvgConf from "@tvg/conf";

export const apolloOptionsQuickPicks = (props: {
  tvgRaceIds?: number[];
}): QueryHookOptions => {
  const variables = {
    tvgRaceIds: props.tvgRaceIds,
    ...tvgConf().graphContext()
  };

  return {
    fetchPolicy: "cache-and-network",
    variables
  };
};

export const apolloOptionsRaces = (props: {
  tvgRaceIds?: number[];
}): QueryHookOptions => {
  const variables = {
    tvgRaceIds: props.tvgRaceIds,
    ...tvgConf().graphContext()
  };

  return {
    fetchPolicy: "cache-and-network",
    skip: !props.tvgRaceIds?.length,
    variables,
    errorPolicy: "all"
  };
};
