import { SaddleClothSilk } from "@tvg/design-system/web/shared";
import React from "react";
import OddsButton from "../OddsButton";
import TagContainer from "../TagContainer";
import {
  ButtonContainer,
  Container,
  Description,
  HeaderContainer,
  RunnerName,
  TextContainer
} from "./styled-components";
import { RunnerInfoProps } from "./types";

const RunnerInfo = ({
  handleOnClick,
  isScratched,
  formattedOdds,
  tag,
  summary,
  biNumber,
  saddleColor,
  numberColor,
  timeform,
  horseName
}: RunnerInfoProps) => (
  <Container
    data-qa-label={isScratched ? "runner-info-scratched" : "runner-info"}
    isScratched={isScratched}
    onClick={handleOnClick}
  >
    <SaddleClothSilk
      number={`${biNumber}`}
      saddleColor={saddleColor}
      numberColor={numberColor}
      isScratched={isScratched}
      silkImage={timeform?.silkUrlSvg}
      showSilks
      useUpdatedSilk
      isLarge
    />
    <TextContainer isScratched={isScratched}>
      <HeaderContainer>
        <RunnerName>{horseName}</RunnerName>
        <TagContainer isScratched={isScratched} label={tag} variant="info" />
      </HeaderContainer>
      <Description isScratched={isScratched}>{summary}</Description>
    </TextContainer>
    <ButtonContainer>
      <OddsButton
        formattedOdds={formattedOdds}
        qaLabel="odds-button"
        isScratched={isScratched}
        onClick={handleOnClick}
      />
    </ButtonContainer>
  </Container>
);

export default RunnerInfo;
