import { SET_MODULE, CLEAR_MODULE, Actions } from "./types";

export const setModule = (moduleName: string): Actions => ({
  type: SET_MODULE,
  payload: moduleName
});

export const clearModule = (): Actions => ({
  type: CLEAR_MODULE
});
