import styled, { keyframes } from "styled-components";
import { breakpoints } from "@tvg/design-system";

const pulseAnimation = keyframes`
  0% {
   opacity: 1
  }

  50% {
    opacity: 0.5
  }

  100% {
    opacity: 1
  }
`;

export const SVG = styled.svg`
  animation: ${pulseAnimation} 1.25s infinite;
`;

export const LoadingContainer = styled.div`
  background-color: var(--fd-colors-background-surface);
  padding-bottom: var(--fd-space-space-4);

  @media screen and ${breakpoints.tablet.min.sm} {
    padding: var(--fd-space-space-4);
  }
`;

export const ScrollableContainer = styled.div`
  background-color: var(--fd-colors-background-surface);
  width: 100%;
  display: flex;
  gap: var(--fd-space-space-2);
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scroll-padding-left: var(--fd-space-space-2);

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CardContainer = styled.div`
  scroll-snap-align: start;

  @media screen and ${breakpoints.tablet.max.sm} {
    &:first-of-type {
      padding-left: var(--fd-space-space-4);
    }

    &:last-of-type {
      padding-right: var(--fd-space-space-4);
    }
  }
`;

export const DescriptionContainer = styled.div`
  @media screen and ${breakpoints.tablet.max.sm} {
    padding: 0 var(--fd-space-space-4) var(--fd-space-space-4);
  }
`;
