import { RaceStatusEnum } from "@tvg/ts-types/Race";
import { QPBettingInterest, QPRace, QuickPicksDataType } from "../types";

// Combines data from QuickPicks and Races query
export const getFilteredMergedData = (
  quickPicksData: QuickPicksDataType[],
  allRacesByTvgRaceIds: QPRace[]
) =>
  quickPicksData
    .map((qp) => {
      const raceDetails = allRacesByTvgRaceIds.find(
        (race: QPRace) => race.tvgRaceId === qp.tvgRaceId
      );

      if (!raceDetails) return null;
      const { status } = raceDetails;

      // Remove Pick if Race has Resulted
      if (status.code === RaceStatusEnum.RACE_OFFICIAL) return null;

      // Merge QuickPick with BettingInterest based on selections/biNumber
      const quickPicksWithBettingInterest = qp.quickPicks.map((quickPick) => {
        const matchedSelections = quickPick.selections.flat();

        // Find corresponding betting interests
        const matchedBettingInterests = raceDetails?.bettingInterests.filter(
          (bettingInterest: QPBettingInterest) =>
            matchedSelections.includes(bettingInterest.biNumber)
        );

        return {
          ...quickPick,
          matchedBettingInterests
        };
      });

      return {
        ...qp,
        raceDetails,
        quickPicks: quickPicksWithBettingInterest
      };
    })
    .filter((item): item is NonNullable<typeof item> => item !== null);
