import { BettingInterest, Probable, Race, Runner } from "@tvg/ts-types/Race";
import { WagerType } from "@tvg/ts-types/Wager";
import {
  getCurrentProbableValue,
  parseProbablesString
} from "@fdr/utils/potentialReturnUtils";
import calculateBetTotal, { BetTotal } from "@tvg/api/wtx/BetHelper";
import { formatCurrencyToNumber } from "@tvg/formatter/currency";
import {
  SuggestedRunnersOrder,
  PoolThresholdAmount,
  MinimumWagerAmount
} from "../../redux/types";
import {
  getAllRunnersFromBettingInterests,
  getSuggestedRunnersByOrder,
  prepareRunnerSelection
} from "../runners";

type SRPDetails = {
  srp: {
    suggestedRunners: Runner[];
    betTotal: BetTotal;
    probablesValue: string;
  };
};

export type RaceWithSRPDetails = Race & SRPDetails;

export const filterByValidRacesForSRP = (
  races: Race[],
  poolThresholdAmount: PoolThresholdAmount
): Race[] =>
  races?.filter(
    (race) =>
      race.bettingInterests?.length &&
      race.probables?.length &&
      race.probables?.[0]?.amount >= poolThresholdAmount
  ) ?? [];

/**
 * Input: a list of races
 * Returns a list of races that,
 * - have probables
 * - have bettingInterests
 * - have pool amount greater than specified pool amount threshold
 * - have enough non-scratched runners to be suggested for SRP (Exacta Box)
 * - have suggested bets where probables value is not $0
 * - have suggested bets where probables value is greater than bet total
 * and attaches suggestedRunners, betTotal, and probablesValue to the races
 * that meet the criteria above.
 *
 * Returns an empty list otherwise
 *
 * Notes: For SRP MVP,
 * - race.probables[0] is of type "EX"
 * - race.wagerTypes[0] is of type "EXB"
 */
export const getRacesWithSuggestedRunnersForSRP = ({
  races,
  thirdPartyPicksSuggestionOrder,
  poolThresholdAmount,
  minimumWagerAmount
}: {
  races: Race[];
  thirdPartyPicksSuggestionOrder: SuggestedRunnersOrder;
  poolThresholdAmount: PoolThresholdAmount;
  minimumWagerAmount: MinimumWagerAmount;
}): RaceWithSRPDetails[] => {
  const validRacesForSRP = filterByValidRacesForSRP(races, poolThresholdAmount);

  if (!validRacesForSRP.length) {
    return [];
  }

  const racesWithSuggestedRunners = validRacesForSRP.reduce(
    (racesAccumulator: RaceWithSRPDetails[], race: Race) => {
      const allRunners = getAllRunnersFromBettingInterests(
        race?.bettingInterests as unknown as BettingInterest[]
      );
      const suggestedRunners = getSuggestedRunnersByOrder(
        allRunners,
        thirdPartyPicksSuggestionOrder
      );

      if (!suggestedRunners.length || !race.wagerTypes?.[0])
        return racesAccumulator;

      const preparedRunners = prepareRunnerSelection(suggestedRunners);
      const probablesValue = getCurrentProbableValue(
        race.probables as unknown as Probable[],
        race.probables?.[0]?.wagerType as WagerType,
        race.wagerTypes?.[0].minWagerAmount ?? minimumWagerAmount,
        preparedRunners.asStrings
      );

      if (!probablesValue || probablesValue === "0") return racesAccumulator;

      const betTotal = calculateBetTotal(
        race.wagerTypes?.[0].minWagerAmount ?? minimumWagerAmount,
        race.wagerTypes?.[0],
        preparedRunners.asNumbers
      );

      const probablesList = parseProbablesString(probablesValue);
      const lowerProbable = formatCurrencyToNumber(probablesList[0]);

      if (formatCurrencyToNumber(betTotal.betCost) >= lowerProbable) {
        return racesAccumulator;
      }

      racesAccumulator.push({
        ...race,
        srp: {
          suggestedRunners,
          betTotal,
          probablesValue
        }
      });
      return racesAccumulator;
    },
    []
  );

  return racesWithSuggestedRunners;
};
