export type ModuleState = string | null;

export const SET_MODULE = "SET_MODULE";
export const CLEAR_MODULE = "CLEAR_MODULE";

export interface SetModuleAction {
  type: typeof SET_MODULE;
  payload: string;
}

export interface ClearModuleAction {
  type: typeof CLEAR_MODULE;
}

export type Actions = SetModuleAction | ClearModuleAction;
