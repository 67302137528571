import { gql } from "@apollo/client";
import { RACE } from "../fragments/Race";

export const GET_RACES_SUBSCRIPTION = gql`
  subscription raceUpdateByTvgRaceIds($tvgRaceIds: [Long]) {
    raceUpdateByTvgRaceIds(tvgRaceIds: $tvgRaceIds) {
      ...race
    }
  }
  ${RACE}
`;
