import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUpcomingRaces } from "@urp/lib-racetracks";
import parseJSONCapiMessage from "@tvg/utils/capiUtils";
import { RaceStatusEnum } from "@tvg/ts-types/Race";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import { Paragraph, Carousel } from "@tvg/design-system/web";
import { setModule } from "@urp/shared-redux/BetSource/actions";
import useSrpRaces from "../../hooks/useSrpRaces";
import {
  getRacesWithSuggestedRunnersForSRP,
  RaceWithSRPDetails
} from "../../utils/races";
import { buildSameRaceParlayWagerUrl } from "../../utils/wagers";
import { defaultSrpConfig } from "../../redux/defaultValues";
import { SrpConfig } from "../../redux/types";
import LoadingModule from "../LoadingModule";
import SameRaceParlayCard from "../SameRaceParlayCard";
import { SameRaceParlayModuleContainer } from "./styled-components";
import { parlayCardCTAEvent } from "../../amplitude";
import { SRPWagerBuilderParams } from "../types";

interface SameRaceParlayModuleProps {
  qaLabel?: string;
  title?: string;
}

const SameRaceParlayModule = ({
  qaLabel = "srpModule",
  title
}: SameRaceParlayModuleProps) => {
  const dispatch = useDispatch();
  const srpModuleMessages: { [key: string]: string } = useSelector((store) =>
    parseJSONCapiMessage(store, "capi.messages.sameRaceParlayModule")
  );
  const srpConfigFromStore = useSelector((store) =>
    parseJSONCapiMessage(store, "capi.messages.sameRaceParlayConfig")
  );
  const srpConfig: SrpConfig = Object.assign(
    defaultSrpConfig, // fallback for individual key-value pairings
    srpConfigFromStore
  );
  const { upcomingRaces, loading: loadingUpcomingRaces } = useUpcomingRaces({
    maxResults: srpConfig.upcomingRacesMaxResults
  });
  const tvgRaceIds =
    upcomingRaces?.map((upcomingRace) => upcomingRace?.tvgRaceId) ?? [];
  const { srpRaces, loading: loadingSrpRaces } = useSrpRaces({ tvgRaceIds });

  // For some reason Storyblok is not configuring desktop title across the app
  // so the SRP Module will follow what the other components are doing to
  // have the title show on desktop dimensions via the Carousel component
  const isDesktop: boolean = useMediaQuery(breakpoints.tablet.min.sm);
  const navigate = useNavigate();

  if (loadingUpcomingRaces || loadingSrpRaces) {
    return <LoadingModule />;
  }

  const racesWithSuggestedRunnersForSrp = getRacesWithSuggestedRunnersForSRP({
    races: srpRaces,
    thirdPartyPicksSuggestionOrder: srpConfig.thirdPartyPicksSuggestionOrder,
    poolThresholdAmount: srpConfig.poolThresholdAmount,
    minimumWagerAmount: srpConfig.minimumWagerAmount
  });

  if (!racesWithSuggestedRunnersForSrp.length) return null;

  const handleButtonPress = (
    redirectUrlArgs: SRPWagerBuilderParams,
    showConfirmation: boolean = false
  ) => {
    dispatch(setModule("same_race_parlay"));
    navigate(
      buildSameRaceParlayWagerUrl({
        ...redirectUrlArgs,
        ...(showConfirmation ? { showConfirmation: true } : {})
      })
    );
  };

  return (
    <SameRaceParlayModuleContainer data-qa-label={qaLabel}>
      {!isDesktop && (
        <Paragraph
          qaLabel={`${qaLabel}-title`}
          ml="var(--fd-space-space-4)"
          mb="var(--fd-space-space-4)"
        >
          {srpModuleMessages.description}
        </Paragraph>
      )}
      <Carousel
        qaLabel={`${qaLabel}-carousel`}
        slidesToScroll="auto"
        slideSize={301}
        title={title}
        displayHeader={isDesktop}
        headerChildren={
          <Paragraph qaLabel={`${qaLabel}-desktop-title`}>
            {srpModuleMessages.description}
          </Paragraph>
        }
        items={racesWithSuggestedRunnersForSrp.map(
          (race: RaceWithSRPDetails, index: number) => {
            const raceTrackRedirectUrlArgs = {
              trackAbbr: race.trackCode as string,
              trackName: race.trackName as string,
              raceNumber: race.number
            };
            const wagersRedirectUrlArgs = {
              ...raceTrackRedirectUrlArgs,
              bet: `${race.srp.betTotal.betAmount}`,
              wt: race.probables?.[0]?.wagerType.code as string,
              suggestedRunners: race.srp.suggestedRunners
            };

            const handleParlayCardCTAEvent = (
              linkUrl: string,
              linkText: string
            ) =>
              parlayCardCTAEvent({
                raceNumber: race.number,
                trackName: race.trackName,
                position: index + 1,
                linkUrl,
                linkText
              });

            return (
              <SameRaceParlayCard
                key={`srpCard-${race.id}`}
                qaLabel={`srpCard-${race.id}`}
                trackName={race.trackName ?? ""}
                raceNumber={`${race.number}`}
                mtp={race.mtp}
                racePostTime={race.postTime}
                raceStatus={race.status.code as RaceStatusEnum}
                wagerAmount={race.srp.betTotal.betAmount ?? 0}
                betTotal={race.srp.betTotal.betCost}
                betType={race.srp.betTotal.wagerName}
                suggestedRunners={race.srp.suggestedRunners}
                approxPay={race.srp.probablesValue}
                onHeaderPress={() => {
                  handleParlayCardCTAEvent(
                    buildSameRaceParlayWagerUrl(raceTrackRedirectUrlArgs),
                    "header"
                  );
                  handleButtonPress(raceTrackRedirectUrlArgs);
                }}
                onEditButtonPress={() => {
                  handleParlayCardCTAEvent(
                    buildSameRaceParlayWagerUrl(wagersRedirectUrlArgs),
                    "edit"
                  );
                  handleButtonPress(wagersRedirectUrlArgs);
                }}
                onPlaceBetButtonPress={() => {
                  handleParlayCardCTAEvent(
                    buildSameRaceParlayWagerUrl({
                      ...wagersRedirectUrlArgs,
                      ...{ showConfirmation: true }
                    }),
                    "place_bet"
                  );
                  handleButtonPress(wagersRedirectUrlArgs, true);
                }}
              />
            );
          }
        )}
      />
    </SameRaceParlayModuleContainer>
  );
};

export default SameRaceParlayModule;
